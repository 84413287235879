import termsEN from './markdown/vouchers/termsEN.md?raw'

export default {
  routes: {
    restaurants: 'restaurants',
    ecommerceHome: 'orders',
    cart: 'cart',
    staticMenu: {
      linkTitle: 'Products',
      slug: 'menu',
    },
    checkout: 'checkout',
    profile: 'my-account',
    personalData: 'personal-data',
    orders: 'my-orders',
    signInSecurity: 'sign-in-and-security',
    myDevices: 'my-devices',
    downloadApp: 'download-the-app',
  },
  auth: {
    login: 'Login',
    register: 'Register',
    logOnTo: 'Enter',
  },
  common: {
    seeAll: 'See all',
    priceFrom: 'From ',
    seeMore: 'See more',
    and: 'and',
    continue: 'Continue',
    return: 'Back',
    accept: 'Accept',
    MOP: 'PickUp',
    DLV: 'McDelivery',
    seeDetail: 'See details',
    startOrder: 'Start order',
    start: 'Select',

    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    areas: {
      curb: 'In parking',
      aut: 'AutoMac',
      ealm: 'At the table',
      mop: 'At the counter',
      dlv: 'McDelivery',
    },
    time: {
      hour: 'hour',
      hours: 'hours',
      minute: 'minute',
      minutes: 'minutes',
      second: 'second',
      seconds: 'seconds',
    },
    toast: {
      clipboard: 'Copied to clipboard',
    },
    o: 'or',
    freeShippingLabel: 'Free shipping',
    free: 'Free',
    offer: 'Offer',
  },
  home: {
    title: 'Home',
    products: 'Products',
    categories: 'Categories',
    search: 'Search an address',
    searchButton: 'Start',
    searchTitle: 'How do you want your order today?',
    searchSubtitle:
      'Select {pickup} if you are picking up your order or McDelivery if you prefer to have it shipped to an address',
    searchLocateMe: 'Use my current location',
    smartcards: {
      multiple: {
        title: 'You have multiple orders in progress',
        secondary: 'View orders',
      },
      pending: {
        title: 'Order pending payment',
        secondary: 'View details',
        primary: 'Pay',
      },
      pendingPaymentCounter: {
        title: 'Pay at counter',
        secondary: 'View details',
        primary: 'Arrived',
      },
      afterPayment: {
        pretitle: "Go to McDonald's",
        title: 'Order in progress',
        secondary: 'View detail',
        primary: 'Arrived',
      },
      advanceSale3days: {
        title: 'The day of your delivery will soon arrive',
        secondary: 'Learn more',
      },
      advanceSaleInDate: {
        title: 'Order ready for delivery',
        secondary: 'Learn more',
        primary: 'Receive my order',
      },
      accepted: {
        title: 'Your order was accepted',
        secondary: 'View detail',
      },
      acceptedPaymentCounter: {
        title: 'Pay at counter',
        secondary: 'View details',
      },
      preparing: {
        title: 'Order in preparation',
        secondary: 'View detail',
      },
      delivering: {
        title: 'Your order is on its way',
        secondary: 'View detail',
        primary: 'Follow my order',
      },
      ready: {
        title: 'Order ready for delivery',
        secondary: 'View detail',
      },
      delivered: {
        title: 'Your order has been delivered',
        secondary: 'View detail',
        primary: 'Repeat order',
      },
    },
  },
  sidebar: {
    title: {
      normal: 'Enjoy the',
      bold: 'best experience',
    },
  },
  onboarding: {
    next: 'Continue',
    stepRestaurant: {
      title: 'Choose how you prefer it!',
      description:
        '<ul><li><b>McDelivery</b> order and receive anywhere</li><li><b>{pickup}</b> find a restaurant to pick up your order</li></ul>',
    },
    stepRestaurantNoDlv: {
      title: 'Choose how you prefer it!',
      description: '<ul><li><b>{pickup}</b> find a restaurant to pick up your order</li></ul>',
    },
    stepMOP: {
      title: '{pickup}',
      description: 'Select {pickup} if you are picking up your order',
    },
    stepDLV: {
      title: 'McDelivery',
      description: 'Select McDelivery if you prefer to have it shipped to an address',
    },
    stepCart: {
      title: 'View your order from here',
      description: 'Find all the products you selected and complete your purchase',
    },
    stepAccount: {
      title: 'Discover "My Account"',
      description:
        'Complete your profile. Review your preferences and ensure you have a unique experience!',
    },
    stepQR: {
      title: 'Join McID',
      description: "Scan the QR code to identify yourself quickly and join McDonald's",
    },
  },
  dataOnboarding: {
    emailValidated: 'Email validated successfully',
    succeeded: 'Successful registration',
  },
  productsSection: {
    linkTitle: 'Products',
    slug: 'products',
  },
  profile: {
    title: 'My Account',
    account: 'Account',
    downloadApp: 'Download App',
    help: 'Help',
    sections: {
      personalData: 'Personal data',
      fav: 'Favorites',
      addressSaved: 'Saved addresses',
      favRestaurants: 'Favorite restaurants',
      favProducts: 'Favorite products',
      historyOrders: 'Order history',
      orderDetail: 'Order detail',
      paymentMethods: 'Payment methods',
      signInSecurity: 'Sign-in and Security',
      manageDevices: 'Manage Devices',
      billData: 'Billing information',
      notification: 'Notifications',
      config: 'Configuration',
      logout: 'Logout',
    },
    notLogged: {
      title: 'We welcome you to <b>McDonald’s</b>',
      subtitle: 'Log in to enjoy our products, benefits and more.',
    },
    subSections: {
      personalData: {
        fullName: 'Name and surname',
        email: 'E-mail address',
        phone: 'Phone',
        password: 'Password',
        birthday: 'Birthday',
        identityCard: 'Identity card',
        dataNotProvide: 'Data not provided',
        formHeaders: {
          fullName: {
            title: 'Name and Surname',
            subtitle: 'What would you like us to call you?',
            button: 'Save',
          },
          phone: {
            title: 'Telephone number',
            subtitle:
              'In order to ensure that only you have access to your account, please enter your phone number',
            button: 'Save',
            buttonAlt: 'Validate and save',
            verified: 'Phone validated',
            notVerified: 'Phone not validated',
          },
          birthday: {
            title: 'Date of birth',
            subtitle:
              'Please enter your date of birth. This way, we can guarantee the security of your information.',
            button: 'Continue',
          },
          identityCard: {
            title: 'Identity card',
            subtitle:
              'Please enter your identity number. This way, we can guarantee the security of your information.',
            button: 'Continue',
          },
        },
      },
      manageDevices: {
        text: "You accessed your account from these devices. If you don't recognize one, you can log out or change the access data",
        thisDevice: 'On this device',
        logout: 'Sign out',
        logoutAll: 'Sign out of all sessions',
        closeModals: {
          title: 'Are you sure you want to sign out of this session?',
          body: '{name} - {appName}{location}, {timeAgo}',
          btnPrimary: 'Sign out',
          btnSecondary: 'Cancel',
        },
        closeAllModals: {
          title: 'Are you sure you want to sign out of all open sessions?',
          body: 'All sessions will be closed except for the one on this device',
          btnPrimary: 'Sign out',
          btnSecondary: 'Cancel',
        },
        toast: {
          success: 'Session closed successfully',
          successAll: 'Sessions closed successfully',
          error: 'There has been an error. Please try again',
        },
        errorModal: {
          title: 'An error occurred while trying to remove this device',
          titleAll: 'An error occurred while trying to remove all devices',
          body: 'Check your connection and try again',
          bodyRetry: 'You can try again or cancel',
          button: 'Try again',
        },
      },
    },
    VError: {
      required: 'Required field',
      customPattern: 'Field is not formatted correctly',
      strongPassword:
        'The password must contain a minimum of 8 characters, 1 uppercase letter, 1 number and 1 special character.',
      passwordMatch: 'Passwords must match.',
      email: 'Invalid email address',
      nameValidation: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces',
      ageValidation: 'You must be at least {minAge} years old to use the app.',
      minLength: 'The field does not meet the minimum number of characters',
      maxLength: 'The field does not meet the maximum number of characters',
      cpfValidation: 'The document is not correctly formatted',
      cpfVerified: 'Incorrect number',
      phoneValidation: 'The number is not in correct format',
      invalidAge: 'You do not meet the required age',
      duplicatePhone: 'Phone already registered',
    },
    inputs: {
      dropdownPlaceholder: 'Select one',
      documentNumberPlaceholder: 'Document number',
      phoneNumberPlaceholder: 'Phone number',
      labelName: 'Name',
      labelLastName: 'Last name',
      labelPrefix: 'Prefix',
      labelSufix: 'Number',
      labelCpfType: 'Document type',
      labelCpf: 'Document',
    },
    tooltipProfile: {
      greeting: 'Hello',
    },
  },
  productDetail: {
    customization: {
      title: 'Personalize your product',
      customizingTitle: 'How do you want to customize?',
      extra: 'Extra:',
      actions: {
        save: 'Save',
        customize: 'Personalize',
        select: 'Select',
        change: 'Change',
        add: 'Add',
      },
      chooseOne: 'Choose one ',
      mandatory: '(Required)',
    },
    sizes: {
      title: 'Select size',
      types: {
        SMALL: {
          letter: 'S',
          title: 'Small',
        },
        MEDIUM: {
          letter: 'M',
          title: 'Medium',
        },
        LARGE: {
          letter: 'L',
          title: 'Large',
        },
      },
    },
    actions: {
      payNow: 'Pay now',
      addToCart: 'Add to cart',
      save: 'Save',
    },
    alerts: {
      outdaypart: 'This product is not available at this time',
      mcDia: {
        expired: {
          title: 'Product Gran Día out of date',
          content: 'This product is no longer available',
        },
      },
      notAvailableAreas: {
        title: 'Delivery zone not available: {value}',
        titleMultiple: 'Delivery zones not available: {value}',
      },
      mismatching: {
        NO_RESTAURANT_SELECTED: 'You have no restaurant selected',
        MISMATCHING_AREA: {
          MOP: 'This product is only available for local pickup',
          DLV: 'This product is only available for home delivery',
        },
        MISMATCHING_RESTAURANT: 'This product is not available at your restaurant',
        MISMATCHING_AREA_RESTAURANT: 'This product is not available at your restaurant',
      },
    },
    mismatching: {
      alerts: {
        NO_RESTAURANT_SELECTED: {
          title: 'You have no restaurant selected',
          body: 'This product is associated with restaurant {restaurantName}',
          accept: 'Continue',
          cancel: 'Select another restaurant',
        },
        MISMATCHING_AREA: {
          titleMOP: 'This product is only available for local pickup',
          titleDLV: 'This product is only available for home delivery',
          body: '',
          accept: 'Select another restaurant',
          cancel: '',
        },
        MISMATCHING_RESTAURANT: {
          title: 'This product is not available in your restaurant',
          body: '',
          accept: 'Search in my restaurant',
          cancel: 'Select another restaurant',
        },
        MISMATCHING_AREA_RESTAURANT: {
          title: 'This product is not available in your restaurant',
          body: '',
          accept: 'Continue shopping',
          cancel: 'Select another restaurant',
        },
        notFoundProductOnCatalog: {
          title: 'This product could not be found in your restaurant',
          body: '',
          accept: 'View catalog',
        },
      },
    },
  },
  productDetailStatic: {
    menu: 'Menu',
    moreInfo: 'More information',
    nutritional: {
      title: 'Nutritional Information',
      type: 'Nutritional Information',
      byProduct: 'By Product',
      idr: 'I.D.R',
      empty: 'This product has no nutritional information',
      items: {
        weight: 'Weight',
        calories: 'Calories (Kcal)',
        sugarTotals: 'Total sugars',
        sugarAdded: 'Added sugars',
        lipids: 'Fats',
        saturatedFats: 'Saturated Fats',
        transFats: 'Trans fat',
        fiber: 'Fiber',
        carbohydrates: 'Carbohydrates',
        proteins: 'Proteins',
        sodium: 'Sodium',
      },
    },
    allergens: {
      title: 'Allergen information',
      tableTitle: 'This product contains',
      empty: 'This product does not contain allergens',
      items: {
        twilight: 'Twilight yellow tint',
        allura: 'Allura red food coloring',
        fish: 'Fish',
        milk: 'Milk',
        soy: 'Soy',
        almonds: 'Almonds',
        lactose: 'Lactose',
        pepper: 'Bell Pepper',
        grain: 'Wheat',
        tartrazine: 'Tartrazine yellow dye',
        egg: 'Egg',
        gluten: 'Gluten',
        walnuts: 'Walnuts',
        sulphites: 'Sulfites',
        cereals: 'Cereals',
        mustard: 'Mustard',
        nuts: 'Dried Fruits',
        sesame: 'Sesame',
        crustaceans: 'Crustaceans',
        peanut: 'Peanut',
        latex: 'Natural Latex',
        wheat: 'Wheat, oatmeal, barley, rye',
        hazelnuts: 'Hazelnuts',
        cashewnuts: 'Cashew nuts',
        brazilnuts: 'Brazil nuts',
        macadamias: 'Macadamias',
        pecans: 'Pecans',
        pistachios: 'Pistachios',
        pinoli: 'Pinoli',
        chestnuts: 'Chestnuts',
      },
    },
  },
  shoppingCart: {
    title: 'My order',
    products: 'Products',
    total: 'Total',
    subtotal: 'Subtotal',
    shipping: 'Shipping',
    serviceFee: {
      name: 'Service Fee',
      info: 'Fee that covers platform operation costs and order tracking',
    },
    tip: 'Tip',
    tax: 'Taxes',
    points: 'Points consumed',
    extras: 'Extras',
    summary: 'Summary of your order',
    empty: {
      title: 'You have not added any product yet',
      text: 'Choose what you like from our menu or from our offers, and enjoy it at home or at the McDonald’s restaurant of your choice',
    },
    suggestive: {
      title: 'Anything else you would like to add?',
    },
    actions: {
      back: 'Continue shopping',
      next: 'Next',
      clear: 'Clear',
      edit: 'Edit',
      seeCart: 'View cart',
    },
    promotions: {
      addPromo: {
        title: 'Discount codes and promotions',
        add: 'Add',
        prefix: {
          coupon: 'Code',
          employee: 'Employee discount',
        },
        terms: {
          prefix: 'For more information please refer to the',
          link: 'terms and conditions',
          sufix: 'of the discount code.',
        },
      },
      modal: {
        title: 'Discounts and promotions',
        disclaimer: 'Remember that discounts and promotions cannot be combined with each other',
        fields: {
          coupon: {
            label: 'Code',
          },
          employee: 'Employee discount',
        },
        codeError: 'Sorry. The discount code is invalid',
      },
    },
    alerts: {
      delete: {
        title: 'Do you want to remove this product?',
        body: 'When you delete it, any customization you have made will also be lost.',
        cancel: 'Cancel',
        accept: 'Delete',
      },
      login: {
        title: 'You must be registered to continue',
        body: 'Click on login to enjoy the full experience and many other benefits',
        cancel: 'Not now',
        accept: 'Login',
      },
      clear: {
        title: 'You will remove all products from your cart',
        body: 'Are you sure?, all items you currently have in your cart will be emptied',
        cancel: 'Cancel',
        accept: 'Empty',
      },
      cancelOrderConfirmation: {
        title: 'Are you sure you want to cancel your order?',
        accept: 'Cancel Order',
        body: 'No problem! Your money will be refunded in 1 or 2 business days depending on the bank.',
        cancel: 'Return',
      },
      deletePromotedConfirmation: {
        title: 'Do you want to delete it?',
        body: 'If you do so, all the products corresponding to the promotion will be deleted.',
        accept: 'Delete',
        cancel: 'Cancel',
      },
    },
    toast: {
      orderMcdia: 'This promotion is not compatible with the products of your order',
    },
  },
  checkout: {
    title: 'Payment detail',
    checkoutAlert: 'Message',
    actions: {
      pay: 'Pay',
    },
    sections: {
      address: {
        title: 'Shipping address',
      },
      tip: {
        title: 'Tipping your delivery man',
        description:
          'This tip is voluntary and is given to your delivery driver in recognition of his work.',
        chips: {
          custom_amount: 'Other',
          custom_percent: 'Other',
        },
        toast: {
          max: 'The maximum tip percentage is {max} %',
        },
      },
      paymentMethods: {
        title: 'Payment method',
        add: 'Add new card',
        modify: 'Modify cards',
        methodTypes: {
          credit: 'Credit',
          debit: 'Debit',
          mop: 'At the counter',
          MERCADO_PAGO_CHECKOUT_PRO: 'Money in account',
        },
        extraMethods: {
          MERCADO_PAGO_CHECKOUT_PRO: 'Mercado Pago',
        },
        expired: 'Expired',
        toast: {
          cardSave: {
            success: 'Card saved successfully',
            error: 'It was not possible to save the card',
          },
          yappy:
            'Remember that when placing your order through Yappy, it cannot be canceled within our app.',
        },
        modifyCards: {
          disclaimer: 'Remember that you can store up to a maximum of {value} cards.',
          actions: {
            add: 'Add card',
          },
          expiration: 'Expiration',
          alerts: {
            deleteCard: {
              title: 'Do you want to delete this card?',
              accept: 'Delete',
              cancel: 'Cancel',
            },
          },
        },
        transition: {
          title: 'We have received your order :)',
          subtitle: 'Waiting for payment confirmation.',
        },
        exclusivePayment:
          'Please note that only the payment method selected below is available for this promotion.',
      },
      fiscal: {
        title: 'Billing data',
        filled: {
          delete: "I don't want invoice",
          action: 'Change',
        },
        unfilled: {
          subtitle: 'Do you want to request invoice?',
          subtitleMandatory:
            'For purchases equal to or greater than {price} you must enter your information on the invoice.',
          subtitleMandatoryAlways: 'The invoice is mandatory',
          action: 'Request',
        },
        modal: {
          action: 'Confirm',
          checkbox: 'Remember for future purchases',
        },
        alert: {
          mandatory: {
            title: 'Enter your billing information to complete the purchase',
            bodyMandatoryAlways: 'Mandatory data',
            bodyMandatory: 'Mandatory data for purchases greater than or equal to {price}',
            accept: 'Accept',
            cancel: '',
          },
        },
      },
    },
    paymentResult: {
      yuno: {
        SUCCESS: {
          MOP: {
            title: 'Payment successfully completed',
            description: "When you're at the restaurant, choose a pickup option for your order",
            actions: {
              accept: 'Withdrawal options',
              acceptMcdia: 'See my orders',
              alternative: 'Choose later',
            },
          },
          DLV: {
            title: 'Payment successfully completed',
            description: 'The order is being prepared. You will soon receive it at your address.',
            actions: {
              accept: 'Track delivery',
              acceptMcdia: 'Return to home',
              alternative: 'View order details',
            },
          },
        },
        ERROR: {
          paymentDidNotHappen: {
            title: 'Payment defaulted',
            description:
              'There has been a payment failure. Please try again or choose another card. If the problem persists contact your bank.',
            actions: {
              accept: 'Try again',
              alternative: 'Choose or insert another card',
            },
          },
          genericError: {
            title: 'Something went wrong 🙁',
            description:
              'Check that the data has been entered correctly. You can try again or choose another card. If the problem persists contact the bank.',
            actions: {
              accept: 'Choose or insert another card',
              alternative: 'Try again',
            },
          },
        },
      },
      legacy: {
        success: {
          title: 'Your payment was successful',
          description:
            'Follow the instructions on the next screen and you will be able to pick up your order as you wish.',
          actions: {
            accept: 'Understood',
            acceptMcdia: 'See my orders',
          },
        },
        restaurantOrderError: {
          title: 'We are sorry! Your order could not be created',
          description: 'An error occurred creating the order in the restaurant',
          actions: {
            accept: 'See order',
          },
        },
        paymentError: {
          title: 'We are sorry! Your payment could not be completed',
          description:
            'There was a problem processing the transaction. Please try again or choose another payment method.',
          actions: {
            accept: 'See order',
            alternative: 'Choose another payment method',
          },
        },
      },
    },
  },
  pickupMethods: {
    farAway: {
      title: 'You are far from the restaurant',
      subtitle:
        'In a few minutes your order will be ready. Are you sure you want to choose a delivery option and start preparation?',
      actions: {
        continue: 'Delivery options',
        imOnWay: 'I am on my way',
      },
    },
    areaSelection: {
      backTitle: 'Pickup methods',
      info: 'Order preparation starts here. In a few minutes your order will be ready',
      areas: {
        title: 'How would you like to pick up your order?',
        MOP: {
          title: 'At counter',
          description: 'Go to the delivery area',
          step: {
            title: 'How do you prefer your order?',
            subtitle: 'Choose the option you want so we can prepare it',
            type: {
              EATIN: 'Dine in',
              TAKEOUT: 'Wrap to go',
            },
          },
          area: {
            value: 'Number',
            title: 'Provide the number of your order in the delivery area.',
            titleDualPoint: 'Pick up your order when your number appears on the board',
            titleInPerson: 'Go to the counter to pay for your order',
            subtitle: 'We are preparing your order',
            subtitleInPerson: 'Order preparation will begin once payment has been completed.',
            alerts: {
              noOrderNumber: {
                title: 'Could not get pickup number',
                accept: 'Go to order detail',
                body: '',
                cancel: 'Cancel',
              },
            },
          },
        },
        AUT: {
          title: 'Drive Thru',
          description: 'Approach the speaker and announce yourself',
          step: {
            title: 'Your order will be delivered by AutoMac',
            subtitle:
              'Preparation will begin upon confirmation of the method of pickup and the order cannot be cancelled.',
            action: 'Confirm',
          },
          area: {
            value: 'Number',
            title: 'Enter and advance to the speaker to announce yourself and pick up your order',
            subtitle: '',
          },
        },
        EALM: {
          title: 'At table',
          description: 'We deliver your order to your table',
          step: {
            title: 'Write your table number',
            subtitle:
              'Preparation will begin upon confirmation of the pickup method and the order cannot be cancelled',
            help: 'You will see it attached at one end',
            action: 'Confirm',
          },
          area: {
            value: 'Table',
            title: 'Now you just have to wait!',
            subtitle: 'Soon you will receive your order ready to enjoy',
          },
        },
        CURB: {
          title: 'Parking lot',
          description: 'We deliver to your garage',
          step: {
            title: 'Enter your garage number',
            subtitle:
              'Preparation will be initiated upon confirmation of the pickup method and the order cannot be cancelled',
            help: 'Find the marked garages so that we can deliver your order',
            helpLink: 'Are the garages busy?',
            action: 'Confirm',
          },
          area: {
            value: 'Garage',
            title: 'Now you just have to wait!',
            subtitle: 'Soon you will receive your order ready to enjoy',
          },
        },
        disabled: {
          title: 'The {areaTranslated} service is not available for this order.',
          subtitle: 'Choose another delivery option',
        },
      },
      formErrors: {
        required: 'Value is required',
        minValue: 'The minimum value is {minimum}',
        maxValue: 'The maximum value is {maximum}',
      },
    },
  },
  errors: {
    page: {
      title: 'Ooops...',
      subtitle: 'Something has gone wrong',
      description: 'It seems that the page you are looking for does not exist',
    },
    toast: {
      geo: {
        denied:
          'Geolocation permission has been denied. Please enable it if you want to use the button to locate you.',
        unsupported: 'Geolocation is not supported by this browser',
        geocoderError: 'An error occurred while getting the address',
        unexpectedError: 'An unexpected error has occurred. Please try again in a few moments',
      },
      generic: 'Sorry, there has been an error. Please try again',
    },
    alerts: {
      outOfDayPart: {
        title: "Product out of hours. Don't forget to cancel your order",
        body: 'Your purchase cannot be delivered, because one of the products is out of schedule. Once canceled, your money will be refunded within 1 or 2 business days',
        accept: 'Cancel order',
        cancel: '',
      },
      orderMcdiaOutOfDates: {
        title: "McDonald's",
        body: 'Deadline expired, order cannot be withdrawn',
        accept: 'OK',
        cancel: '',
      },
      orderNotExist: {
        title: 'The order does not exist',
        body: 'The requested order cannot be found',
        accept: 'Go to products',
        cancel: 'Cancel',
      },
      orderAlreadyPaid: {
        title: 'Order has already been paid',
        accept: 'Accept',
        body: '',
        cancel: 'Cancel',
      },
      generic: {
        title: 'An unexpected error has occurred',
        accept: 'Accept',
        body: 'Try again after a few minutes',
        cancel: '',
      },
      tokenExpired: {
        title: 'Information',
        body: 'Your time to place the order has expired, you must log in again to finish the order.',
        accept: 'Accept',
        cancel: '',
      },
      noCoverage: {
        title: 'McDelivery is not available right now',
        body: 'In the meantime, you can place an order and pick it up at the nearest restaurant with PickUp',
        accept: 'PickUp',
        cancel: 'Try again later',
      },
      noRestaurantsOpened: {
        title: 'Sorry, the restaurant is closed',
        body: 'You can choose another address or see if there is a restaurant nearby to pick up your order',
        accept: 'Search for nearby restaurants',
        cancel: 'Cancel',
      },
      noRiders: {
        title: 'Our delivery drivers are busy',
        body: 'You can try again later or place an order and pick it up with PickUp',
        accept: 'PickUp',
        cancel: 'Try again later',
      },
      productNotAvailable: {
        title: 'The promotion has ended!',
        body: 'Change the items in your order to continue with your purchase',
        accept: 'Return to my order',
        cancel: '',
      },
      pickup_unhandled: {
        title: 'Unable to set pickup method for your order',
        body: '',
        accept: 'Try again',
        cancel: 'Cancel',
      },
      pickup_noRestaurantsOpened: {
        title: 'Restaurant closed',
        body: 'Sorry, the restaurant is closed or about to close and is no longer accepting orders. You will be able to pick up your order when it is open again',
        accept: 'Continue',
        cancel: 'Cancel',
      },
      pickup_serviceNumberEmpty: {
        title: 'Oops! Something unexpected happened!',
        body: 'We had a problem loading the number you entered. Please try again',
        accept: 'Retry',
        cancel: '',
      },
      pickup_anyPickupAreaForThisOrderIsAvailable: {
        title: 'The restaurant is no longer available',
        body: 'You can cancel the order to get a refund and choose another option. We apologize!',
        accept: 'Go to my order',
        cancel: '',
      },
      pickup_orderPickupNotAvailable: {
        title: 'The restaurant is no longer available',
        body: 'You can cancel the order to get a refund and choose another option. We apologize!',
        accept: 'Go to my order',
        cancel: '',
      },
      pickup_areaNotAvailable: {
        title: 'The restaurant is no longer available',
        body: 'You can cancel the order to get a refund and choose another option. We apologize!',
        accept: 'Go to my order',
        cancel: '',
      },
      orderErrorPrice: {
        title: 'Your order could not be processed',
        accept: 'Accept',
        body: 'Please try again after a few minutes',
        cancel: 'Cancel',
      },
      platformNotValidForPromo: {
        title: 'This promotion is not available',
        body: 'Please select another payment method to complete your purchase',
        accept: 'Choose another payment method',
        cancel: '',
      },
      orderInProgressMultiple: {
        title: 'You have reached the maximum number of orders in progress',
        accept: 'My orders',
        body: 'Until you remove at least one of your current orders, you will not be able to start another purchase',
        cancel: 'Cancel',
      },
      orderPickupNotAvailable: {
        title: 'The restaurant selected in a previous order is not available at this time',
        accept: 'Accept',
        body: 'Please select a new restaurant.',
        cancel: 'Cancel',
      },
      orderGeneric: {
        title: 'Could not process your order',
        accept: 'Accept',
        body: 'Please try again after a few minutes',
        cancel: 'Cancel',
      },
      orderInProcess: {
        title: 'Error',
        body: 'The order is on its way',
        accept: 'Accept',
        cancel: 'Cancel',
      },
      promotionNotFound: {
        title: 'The promotion has ended!',
        body: 'Change the items in your order to continue with your purchase',
        accept: 'Return to my order',
        cancel: '',
      },
      orderProductMismatch: {
        title: 'Error creating your order',
        body: 'There has been an error with the creation of your order and it cannot be placed.',
        accept: 'Accept',
        cancel: '',
      },
      countryNotValid: {
        title: 'There has been an error with the payment',
        body: 'The country selected does not correspond to the country in which the order was placed. Please change the country to proceed with the payment.',
        accept: 'Accept',
        cancel: '',
      },
      paymentPendingApproval: {
        title: 'You have already attempted a payment for this order',
        body: 'Payment for your order is pending approval. Check the status of your order in a few minutes',
        accept: 'Accept',
        cancel: '',
      },
      promotionNotAvailable: {
        title: 'The promotion is no longer available and cannot be applied to this order.',
        body: '',
        accept: 'Accept',
        cancel: '',
      },
      orderMcdia: {
        title: 'Your purchase cannot be fulfilled!',
        body: 'McDia Feliz products cannot be purchased with other products. To continue with the purchase you must remove products that do not match the campaign.',
        accept: 'Remove products',
        cancel: '',
      },
      fiscalFieldsRequired: {
        title: 'Enter your billing information to complete the purchase',
        body: 'Required for purchases greater than or equal to {value}',
        accept: 'OK',
        cancel: '',
      },
      antifraudTemporalBlock: {
        title: 'TemporalBlock',
        body: 'For the security of your account, we are temporarily blocking the payment function.',
        accept: 'Back to home page',
        cancel: '',
      },
      antifraudLimitTransactionsReached: {
        title: 'Daily limit of transactions reached',
        body: 'We identified too many consecutive payment transactions. Your account has been temporarily blocked for security reasons.',
        accept: 'Back to home',
        cancel: '',
      },
      phoneValidationRequired: {
        title: 'Validate your phone',
        body: 'You need to validate your phone to place an order',
        accept: 'Accept',
        cancel: '',
      },
      deviceValidationRequired: {
        title: 'Validate your device',
        body: 'You need to validate your device to place an order',
        accept: 'Accept',
        cancel: '',
      },
      promoExclusivePaymentUsed: {
        title: "Oops! You've already used this promotion",
        body: 'Go back to the cart and change the items to proceed with the order',
        accept: 'Return to my order',
        cancel: '',
      },
      limitProductExclusivePayment: {
        title: 'We allow only one promotional item per purchase',
        body: 'You can keep other menu items in this purchase, but you can only add the promotional offer once',
        accept: 'Understood',
        cancel: '',
      },
      limitedPromoProduct: {
        title: 'Your order already includes discounted products',
        body: 'Do you want to keep the current discounts or apply the new code?',
        accept: 'Keep discounts',
        cancel: 'Apply new code',
      },
      employeeDiscount: {
        title: 'Your order already has discounts applied',
        body: 'Do you want to replace the promotion or add the product without a discount?',
        accept: 'Add without a discount',
        cancel: 'Replace promotion',
      },
      areaNotFound: {
        title: 'The restaurant is not available for the selected products',
        body: 'You can choose another restaurant or go back to your order and change the items',
        accept: 'Return to my order',
        cancel: 'Change restaurant',
      },
      limitPriceOrder: {
        title: 'The total value of your purchase is too high',
        body: 'You have exceeded the allowed limit of {value}. Please review the items in your cart',
        accept: 'Return to my order',
        cancel: '',
      },
      restaurantNotLoyalty: {
        title: 'This restaurant is not part of {value}',
        body: 'To continue, change the items in your order or choose another restaurant. If you do, your cart will be emptied',
        accept: 'Return to my order',
        cancel: 'Change restaurant',
      },
      cumulativePromotionsNotAllowed: {
        title: 'Your order already has discounts applied',
        body: 'Do you want to replace the promotion or add the product without a discount?',
        accept: 'Add without a discount',
        cancel: 'Replace promotion',
      },
      autoApplicablePromotionNotAllowed: {
        title: 'Your order already includes discounted products',
        body: 'Do you want to keep the current discounts or apply the new code?',
        accept: 'Keep discounts',
        cancel: 'Apply new code',
      },
    },
  },
  selectRestaurant: {
    DLV: {
      noNumber: 'No number',
      addAddressError: 'This field needs to be completed',
      additionlInfoError: 'This field needs to be completed',
      completeDirection: 'Fill in your address',
      street: 'Street',
      number: 'Number',
      additionalInfo: 'Additional information for the delivery',
      additionalInfoPlaceholder: 'House, flat...',
      additionalInfoDeliveryPlaceholder: 'Help us deliver your order',
      willSendTo: 'We will send your order to',
      confirmLocation: 'Confirm location',
      favorite: {
        saveTitle: 'Save as favorite address',
        formTitle: 'Do you want to name it?',
        fields: {
          alias: {
            label: '*Alias',
            required: 'The alias for the address is required',
          },
        },
      },
    },
    MOP: {
      findYourMcDonalds: 'Find your McDonald’s',
      seeList: 'See list',
      seeMap: 'See map',
      emptyList: 'We have not found nearby restaurants in that location.',
    },
    map: {
      adjustPin: 'Adjust the pin to the right position',
    },
    lupap: {
      city: 'City',
      form: {
        address: 'Address',
        complement: 'Building, tower, apartment ...',
      },
      continue: 'Continue',
    },
  },
  alertDialog: {
    mcDeliveryError: {
      title: "Sorry, we don't have a McDelivery service in your area yet",
      body: 'We are working to be able to offer it to you, but for now choose another address or see if there is a restaurant nearby to pick up your order',
      btnPrimary: 'Find nearby restaurants',
      btnSecondary: 'Find another address',
    },
    ridersError: {
      title: 'Sorry, there are currently no delivery drivers available in your area',
      body: 'You can choose another address or see if there is a restaurant nearby to pick up your order',
      btnPrimary: 'Find nearby restaurants',
      btnSecondary: 'Find another address',
    },
    restaurantClosedError: {
      title: 'Sorry, the restaurant is closed',
      body: 'You can choose another address or see if there is a restaurant nearby to pick up your order',
      btnPrimary: 'Find nearby restaurants',
      btnSecondary: 'Find another address',
    },
    genericError: {
      title: 'Sorry an error occurred',
      body: 'Error getting available restaurants',
      btnPrimary: '',
      btnSecondary: '',
    },
    changeRestaurant: {
      titleMop: 'Are you sure you want to change the pickup restaurant?',
      titleDlv: 'Are you sure you want to change the delivery restaurant?',
      body: 'You will have to start your order again and re-select from the menu.',
      btnPrimary: 'Change restaurant',
      btnSecondary: 'Cancel',
    },
  },
  restaurantTooltipAlert: {
    closed:
      'Remember that the selected restaurant is closed and is not accepting orders at this time',
    riders: 'It seems there are no riders at the moment',
    farFromRestaurant:
      "It seems like you're far from the restaurant. You can always change it by clicking on it.",
    farFromRestaurantOptions: {
      withoutGeo: {
        title: 'Check if this address is correct',
        subtitle:
          'If you change it, activate your location to make it more accurate.<br/><b>Do you want to choose another address?</b>',
      },
      withGeo: {
        title: 'We noticed that you are far from this restaurant',
        subtitle: 'Do you want to choose another one?',
      },
    },
    actions: {
      dontChange: 'No, continue',
      change: 'Yes, change',
    },
    farFromRestaurantWithOrder:
      'We noticed that you are far from the restaurant where you placed the order',
    farFromRestaurantWithoutOrder:
      'We noticed that you are far from the restaurant where you placed the order. You can change it by pressing on the address',
  },
  restaurant: {
    status: {
      closed: 'Closed',
      nearToClose: 'Near to close',
      onlyLocal: 'Only orders for dine-in',
    },

    detail: {
      schedule: 'Schedule',
      header: {
        MOP: 'Pick up at',
        DLV: 'Ship To',
      },
      howToArrive: 'How to arrive',
      mop: 'Pickup methods',
      closed: 'Closed',
      services: {
        title: 'Services',
        breakfast: 'Breakfast',
        mcCafe: 'McCafe',
        timeExtended: '24 hours',
        mcParty: 'McParty',
        playPlace: 'PlaySpace',
        parking: 'Parking',
        wifi: 'WIFI',
        wheelchairAccess: 'Accessibility',
        dessertCenter: 'Dessert Center',
        shoppingCenter: 'Shopping Center',
      },
    },
  },
  help: {
    links: {
      faqs: {
        title: 'Any questions?',
        linkText: 'Help and FAQ’s',
      },
      whatsapp: {
        title: 'Need help?',
        titleAlt: 'We are also in',
        linkText: 'Whatsapp',
      },
      sac: {
        title: 'Customer Service',
        linkText: 'Phone',
      },
    },
  },
  orders: {
    orderNumber: 'Order number',
    delivery: {
      deliveryCode: 'Delivery code',
      sentFrom: 'Sent from',
      description: 'Provide the code to the delivery person only upon receiving your order',
    },
    date: {
      today: 'Today',
      yesterday: 'Yesterday',
    },
    historyOrders: {
      onGoing: 'You have no current order',
      finished: 'You have no completed orders',
    },
    status: {
      pending: 'Pending',
      pendingCounter: 'Pay at counter',
      paymentDone: 'Payment done',
      paymentDoneMcdia: 'Getting ready for the day',
      paymentDoneMcdia3days: 'Getting ready for the day',
      paymentDoneMcdiaInDate: 'Ready to be delivered',
      paymentDoneMcdiaExpired: 'Deadline expired',
      goRestaurant: 'Go to the restaurant',
      received: 'Received',
      accepted: 'Accepted',
      inProgress: 'In preparation',
      ready: 'Ready',
      delivering: 'Delivering',
      canceled: 'Canceled',
      error: 'Error on order',
      delivered: 'Delivered',
      finished: 'Finished',
      pickupExpired: 'Pickup expired',
    },
    areas: {
      aut: 'Delivery at AutoMac',
      ealm: 'Delivery at the table',
      mop: 'Delivery at the counter',
      curb: 'Delivery in parking',
    },
    buttons: {
      onGoing: 'Ongoing',
      finished: 'Finished',
      cancelOrder: 'Cancel order',
      pay: 'Pay',
      reorder: 'Reorder',
      rate: 'Rate',
      arrival: 'Arrival',
      track: 'Follow order',
      receive: 'Receive my order',
    },
    toast: {
      orderCanceled: 'Order canceled',
      orderCanceledError: 'Order could not be canceled',
      orderCanceledMOP: 'The order is being prepared and it is not possible to cancel it',
      orderCanceledDLV:
        'The delivery person is already on his way to your address and it is not possible to cancel it',
      orderUnappliedProducts:
        'The promotion was added, but it does not apply to the products in your order',
    },
    messages: {
      yappy: 'If you have any questions about your order with Yappy, contact us through our chat',
      flexCancelledReason: {
        FLEX_CANCELLED_REFUND:
          "Your order has been canceled. But don't worry, we are already processing your refund. <br/><br/>We apologize!",
        FLEX_CANCELLED_ERR_ADDRESS:
          'Order cancelled. <br/><br/>There was a problem with the shipping address. The money cannot be refunded. Sorry for the inconvenience.',
        FLEX_CANCELLED_ERR_INFO: 'Your order has been cancelled. We apologise!',
      },
    },
    alert: {
      receiveOrder: {
        title: 'We are ready to prepare your order',
        body: 'Remember that it is important to be at the address you chose to receive your order. Are you already at <b>{address}</b> to receive it?',
        accept: 'Confirm',
        cancel: 'Not yet',
      },
    },
    receiveOrder: {
      texts: {
        'no-riders': {
          title: "We're sorry, we couldn't find available drivers for your order",
          body: 'We are working to resolve this as soon as possible. Please try requesting the delivery again in a few minutes.',
        },
        'no-stock': {
          title: "We're sorry, we couldn't start your order due to lack of ingredients",
          body: 'We are working to resolve this as soon as possible. Please try requesting the delivery again in a few minutes.',
        },
        'rest-closed': {
          title: 'At this time, the restaurant is closed',
          body: 'To ensure you can receive your order, make sure to request delivery during our business hours.',
        },
        'generic': {
          title: 'Sorry, something went wrong and we could not start your order.',
          body: 'We are working to resolve this as soon as possible. Please try requesting the shipment again or wait a few minutes to retry.',
        },
      },
      actions: {
        primary: 'Try again later',
        secondary: 'Go to my order',
      },
    },
  },

  SEO: {
    restaurants: {
      title: "Find your nearest McDonald's",
      description:
        "Find the nearest McDonald's restaurant, wherever you are {'|'} Schedules, addresses and menus so you can enjoy your favorites: delivery or {pickup}.",
      city: {
        title: "Find your nearest McDonald's in {city}",
        description:
          "Discover the nearest McDonald's restaurant in {city}. Your favorite burger near you {'|'} Schedules, addresses and menus.",
        restaurant: {
          title: '{restaurant} {city}: Discover our offers',
          description: `Have your McDonald's favorites delivered wherever you are. Order your favorite food with {restaurant} {city} at the best price✓.`,
          orders: {
            title: `My orders at {restaurant} {'|'} McDonald's`,
            description: `Order at {restaurant} in {city} {'|'} Explore our menu and enjoy the convenience of delivery or {pickup}.`,
            area: {
              title: `⇨{area} McDonald's {city}`,
              description: `Find and customize the menu you like best with side dish to choose, fries and drink. Choose the size and order at McDonald's at the best price✓.`,
              category: {
                title: `Order {category} {restaurant}`,
                description: `Your McDonald's order is now closer than ever. Choose your {category} available for delivery or {pickup} at {restaurant}.`,
                product: {
                  title: `Order {product} {restaurant}`,
                  description: `Your McDonald's order is now closer than ever. Enjoy {product} available for delivery or {pickup} at {restaurant}.`,
                },
              },
            },
          },
        },
      },
    },
    menu: {
      title: 'McDonald’s menu - All products',
      description: `Find and customize the menu you like best with side dish to choose, fries and drink. Choose the size and order at McDonald's at the best price✓.`,
      category: {
        title: `{category} McDonald's {'|'} Complete your meal`,
        description: `Hungry? McDonald's has different {category} offers available for {pickup} or delivery at the best price ✓. Choose your favorite meal.`,
        product: {
          title: `{product} - {category} {'|'} McDonald's`,
          description: `Enjoy your {product}, delivery or {pickup}. 24 hours a day at your nearest McDonald's ✓ Discover our new products and offers on the official website.`,
        },
      },
    },

    orders: {
      cart: {
        title: `Checkout {'|'} McDonald’s`,
        description: `Complete your order at McDonald’s {'|'} Find the products you want and proceed to online payment easily and securely {'|'} Enjoy!`,
      },
      cartProductIndex: {
        title: `Checkout {'|'} McDonald’s`,
        description: `Review your order, you can complete it or edit it easily {'|'} Find the products you want and proceed to online payment easily and securely {'|'} Enjoy!`,
      },
      checkout: {
        title: `Checkout {'|'} McDonald’s`,
        description: `Complete your order {'|'} Review your selection and enter your information. Ready to enjoy a delicious meal! Secure payment✓`,
      },
    },

    my: {
      account: {
        title: `My personal data {'|'} McDonald's`,
        description: `Manage your personal data easily and securely at McDonald's {'|'} Update profile, address, and favorites information {'|'} Keep your account up to date.`,
        section: {
          title: `{section} {'|'} McDonald's`,
          description: `Manage your personal data easily and securely at McDonald's {'|'} Update profile, address, and favorites information {'|'} Keep your account up to date.`,
        },
        my: {
          orders: {
            title: `My orders {'|'} McDonald's`,
            description: `View your orders, purchase history, and details of your previous orders {'|'} Track your deliveries and place a new order if desired.`,
            orderId: {
              title: `{orderId} {'|'} McDonald's`,
              description: `View the details of your order {orderId} {'|'} Detailed information about your purchase, delivery status, and products purchased at McDonald's.`,
            },
          },
        },
      },
    },
  },
  phone: {
    phoneValidation: {
      title: 'Phone number',
      subtitle:
        'In order to ensure that only you have access to your account, please enter your phone number.',
      continue: 'Validate and save',
      duplicatePhone: 'The phone number is already registered. Please enter a different one',
    },
    phoneVerification: {
      title: 'Validate your phone',
      subtitle: 'We just sent the six-digit validation code to your phone',
      notSended: 'Did you not receive the code?',
      notSendedDisclaimerCountdown: 'You can apply again in {time}.',
      notSendedDisclaimer: 'You can apply again now.',
      sendAgain: 'Send code again',
      errorCode: {
        CODE_FIELD_ERROR: 'Wrong code',
        CODE_FIELD_ERROR_EXPIRED:
          'The code has expired.<br/>To validate your phone click on “send code again”',
      },
    },
    alert: {
      phoneAlreadyValidated: {
        title: 'Sorry, there was an error in the process',
        body: 'Please try again',
        accept: 'Continue',
      },
    },
    toast: {
      generic: 'Sorry, there was an error. Please try again',
      warning: 'Please wait {time} before requesting a new code again',
      success: 'Phone verified',
    },
    block: {
      title: 'Exceeded shipment limit',
      subtitle: 'We were unable to validate your phone due to excessive submissions.',
      tryAgainCountdown: 'Please try again in {time}.',
      tryAgainNow: 'Please try again now.',
      action: 'Return to home',
    },
  },
  device: {
    deviceVerification: {
      title: 'Validate your device',
      subtitle:
        'We have just sent you the <b>four-digit validation code</b> to your {email}, enter it to continue your order.',
      notSended: 'Did you not receive the code?',
      notSendedDisclaimerCountdown:
        'Remember to check your spam folder. You can request it again at {time}.',
      notSendedDisclaimer: 'Remember to check your spam folder. You can request it again now.',
      sendAgain: 'Send the code again',
      errorCode: {
        CODE_FIELD_ERROR: 'Wrong code',
        CODE_FIELD_ERROR_EXPIRED:
          'The code has expired.<br/>To validate your device click on “send the code again”',
      },
    },
    alert: {
      deviceAlreadyValidated: {
        title: 'Sorry, there has been an error in the process.',
        body: 'Please try again',
        accept: 'Continue',
      },
    },
    toast: {
      generic: 'An error has occurred. Please try again.',
      success: 'Device successfully validated',
    },
    block: {
      title: 'Limit of shipments exceeded',
      subtitle: 'We were unable to validate your device due to excessive shipments.',
      tryAgainCountdown: 'Please try again in {time}.',
      tryAgainNow: 'Please try again now.',
      action: 'Return to home',
    },
  },
  email: {
    emailVerification: {
      title: 'Check your email',
      subtitle:
        'We have just sent you the <b>four-digit validation code</b> to your {email}, enter it to continue your order.',
      notSended: 'Did you not receive the code?',
      notSendedDisclaimerCountdown:
        'Remember to check your spam folder. You can request it again at {time}.',
      notSendedDisclaimer: 'Remember to check your spam folder. You can request it again now.',
      sendAgain: 'Send the code again',
      errorCode: {
        CODE_FIELD_ERROR: 'Wrong code',
        CODE_FIELD_ERROR_EXPIRED:
          'The code has expired.<br/>To validate your email click on “send the code again”',
      },
    },
    alert: {
      emailAlreadyValidated: {
        title: 'Sorry, there has been an error in the process.',
        body: 'Please try again',
        accept: 'Continue',
      },
      validationInProgress: {
        title: 'Are you sure you want to go back?',
        body: 'Remember that by doing so, you may lose the data you have entered so far.',
        accept: 'Go back',
        cancel: 'Cancel',
      },
    },
    toast: {
      generic: 'An error has occurred. Please try again.',
      success: 'Successfully saved data',
      successAlt: 'Email successfully confirmed',
    },
    block: {
      title: 'Limit of shipments exceeded',
      subtitle: 'We were unable to validate your email due to excessive shipments.',
      tryAgainCountdown: 'Please try again in {time}.',
      tryAgainNow: 'Please try again now.',
      action: 'Return to home',
    },
  },
  requiredFields: {
    title: 'Fill in your details',
    subtitle:
      'Enter the missing data to continue with your purchase. This information will be stored securely and we will not ask you for it again',
    actions: {
      continue: 'Continue',
    },
    alert: {
      genericError: {
        title: 'Sorry, there was an error in the process',
        body: 'Please try again',
        accept: 'Continue',
      },
      duplicatePhone: {
        title: 'Phone already registered',
        body: 'The phone number is already registered. Please enter a different one',
        accept: 'OK',
      },
    },
  },
  repeatOrder: {
    success: {
      title: 'Are you sure you want to repeat this order?',
      body: 'The order will be created in the same restaurant where you placed the order. If you have products in your cart they will be substituted for the products in this purchase. The prices of the products may have changed.',
      accept: 'Continue',
      cancel: 'Cancel',
    },
    genericError: {
      title: 'Oops! At this time it will not be possible to repeat the order',
      body: 'In the meantime, take a look at our catalog or try again',
      accept: 'Try again',
      cancel: 'View Catalog',
    },
    noRiders: {
      title: 'Sorry, there are currently no delivery drivers available in your area',
      body: 'We are experiencing high order demand. Please try again in a few minutes.',
      accept: 'Accept',
      cancel: '',
    },
    mopNotAvailable: {
      title: 'Oops! We will not be able to reorder',
      body: 'At this moment this restaurant has no {pickup} available. Please try again in a few minutes.',
      accept: 'Accept',
      cancel: '',
    },
    dlvNotAvailable: {
      title: 'Oops, we will not be able to repeat the order',
      body: 'This restaurant is currently unavailable for McDelivery. Please try again in a few minutes.',
      accept: 'Accept',
      cancel: '',
    },
    restaurantNotFound: {
      title: "Oh no, we couldn't find your restaurant.",
      body: 'We recommend you try reordering from another restaurant',
      accept: 'Understood',
      cancel: '',
    },
    closedRestaurant: {
      title: 'Our restaurant is currently closed',
      body: 'It will not be possible to repeat this order. We suggest you choose another restaurant to make your purchase.',
      accept: 'Understood',
      cancel: '',
    },
    permittedAreasProducts: {
      title: 'Oh no, this order is no longer available',
      body: 'Some of the products in this order cannot be picked up by any of the available pickup methods',
      accept: 'Understood',
      cancel: '',
    },
    outOfDaypartProducts: {
      title: 'Oops! At this time it will not be possible to reorder',
      body: 'Some products are not within your preparation schedule. Please try to repeat another order, or start a new purchase.',
      accept: 'Understood',
      cancel: '',
    },
    notExistingProducts: {
      title: 'Oops! At this time it will not be possible to repeat the order',
      body: 'Some products in this purchase are no longer in the catalog. Please try to repeat another order, or start a new purchase.',
      accept: 'Understood',
      cancel: '',
    },
  },
  contact: {
    title: 'Complete the form with your data',
    inputs: {
      firstname: 'Firstname',
      lastname: 'Lastname ',
      email: 'Email',
      phone: 'Phone',
      city: 'City',
      local: 'Restaurant',
      date: 'Day',
      time: 'Hour',
      message: 'Message',
      newsletterAccepted: "I agree to receive information about McDonald's news and promotions",
      subject: {
        label: 'Reason',
        placeholder: 'Choose one',
        option: {
          suggestion: 'Suggestion',
          inquire: 'Query',
          openDoors: 'Open Doors',
          criticism: 'Criticism',
          claim: 'Claim',
          praise: 'Praise',
          personalData: 'Personal data',
          other: 'Others',
        },
      },
    },
    toast: {
      error: 'Sorry, an unexpected error occurred. Please try again later',
      success: 'The contact form has been submitted successfully',
    },
    action: {
      save: 'Save',
    },
  },
  rating: {
    stars: {
      help: 'Choose from 1 to 5 stars to evaluate your order',
      new: {
        title: 'How was your experience with this order?',
      },
      edit: {
        title: 'Continue rating the last order?',
        actions: {
          notNow: 'Not now',
          continue: 'Continue',
        },
      },
    },
    form: {
      rate: 'Rate your satisfaction with:',
      anyProblem: 'Did you have any problems with the order?',
      send: 'Send',
    },
    finalStep: {
      title: "Thank you for choosing McDonald's!",
      ok: {
        subtitle:
          "We value your feedback and want to make sure your McDonald's experience is the best it can be.",
      },
      ko: {
        subtitle: "We want to make sure your experience at McDonald's is the best it can be.",
        subtitle2:
          "If you have any questions or need assistance, please contact our customer service center - we're here to help!",
      },
    },
    toasts: {
      NOT_FOUND: 'Sorry, order not found',
      ALREADY_RATED: 'This order has already been rated',
      RATING_EXPIRED: 'Sorry, the time to rate this order has expired',
      UNKNOWN: 'Sorry, an unexpected error occurred. Please try again later',
    },
  },
  overrideRestaurant: {
    autoselectRestaurantAndArea: {
      title: 'Are you sure you want to change the restaurant?',
      body: 'If you wish to continue, the products in the current cart will be removed',
      accept: 'Continue',
      cancel: 'Cancel',
    },
    autoselectRestaurantAndAreaError: {
      title: 'An error occurred while selecting the restaurant',
      body: 'The selected restaurant is not available. Sorry, please try again later',
      accept: 'Continue',
      cancel: 'Cancel',
    },
  },
  redirection: {
    error: {
      title: 'Something has gone wrong',
      fallbackLink: 'Click here to continue',
    },
  },
  enrollment: {
    BirthDate: {
      title: 'Date of birth',
      body: 'It is necessary to complete your date of birth to verify that our users meet the minimum age requirement to use the application',
      dateLabel: 'Date of birth',
    },
    Name: {
      title: 'First and last name',
      body: 'How would you like us to call you?',
      label: {
        name: 'First Name',
        lastName: 'Last Name',
      },
    },
    Document: {
      title: 'Identification Document',
      body: 'Enter your identification number. This way, we can ensure the security of your information',
      labelCpfType: 'Document Type',
      dropdownPlaceholder: 'Choose one',
      documentLabel: 'Document Number',
      warningTitle: 'Consult more information',
      warningBody: 'Customer service',
    },
    Phone: {
      title: 'Phone number',
      body: 'To ensure that only you have access to your account, please enter your phone number',
      label: {
        prefix: 'Prefix',
        number: 'Number',
      },
    },
  },
  vouchers: {
    title: 'You have available',
    description: 'You can get a Minecraft code if you have the required points',
    redeemCoupon: {
      title: 'Do you want to redeem <br/><b>{value} pts </b> points to get <br/>a code?',
      accept: 'I want to get a code',
      cancel: 'Not now',
    },
    appliedCoupon: {
      title: 'Congratulations! You just got your Minecraft code',
      useCodeInfo: 'How to use the codes?',
      codeExpiration: 'Your code expires on',
      accept: 'Back',
    },
    codeList: {
      title: 'View my obtained codes',
      description: 'Codes',
      code: 'Code',
      expiration: 'Expiration',
    },
    notification: {
      title: 'Only one code can be activated per Minecraft account',
      description:
        "Redeem your points from { startDate } to { endDate } or until the stock of { stock } units runs out. The code activates 25 McDonald's add-ons in Minecraft: Bedrock Edition",
    },
    errors: {
      generic: "Something didn't go as expected. Please try again later",
      maximumPurchasesExceeded: 'You have exceeded the maximum number of Minecraft code purchases',
      noPointsAvailable:
        "You don't have the required points to get a code. You need { value } pts. to get it",
    },
  },
  stepsReference: 'Step {current} of {total}',
  markdown: {
    vouchers: {
      terms: termsEN,
    },
  },
}
