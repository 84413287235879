import validate from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirects_45global from "/app/middleware/redirects.global.ts";
import trailing_45slash_45global from "/app/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirects_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  "check-token-landing": () => import("/app/middleware/checkTokenLanding.ts"),
  "prevent-countries": () => import("/app/middleware/preventCountries.ts")
}