import termsPT from './markdown/vouchers/termsPT.md?raw'

export default {
  routes: {
    restaurants: 'restaurantes',
    ecommerceHome: 'pedidos',
    cart: 'carrinho',
    staticMenu: {
      linkTitle: 'Cardapio',
      slug: 'cardapio',
    },
    checkout: 'checkout',
    profile: 'minha-conta',
    personalData: 'dados-pessoais',
    orders: 'meus-pedidos',
    signInSecurity: 'login-e-seguranca',
    myDevices: 'meus-dispositivos',
    downloadApp: 'apps',
  },
  auth: {
    login: 'Iniciar sessão',
    register: 'Registrar',
    logOnTo: 'Entrar',
  },
  common: {
    seeAll: 'Ver tudo',
    priceFrom: 'A partir ',
    seeMore: 'Veja mais',
    and: 'e',
    continue: 'Continuar',
    return: 'Voltar',
    accept: 'Aceitar',
    MOP: 'Peça e Retire',
    DLV: 'McDelivery',
    seeDetail: 'Ver detalhes',
    startOrder: 'Começar pedido',
    start: 'Selecionar',
    mon: 'Segunda-feira',
    tue: 'Terça-feira',
    wed: 'Quarta-feira',
    thu: 'Quinta-feira',
    fri: 'Sexta-feira',
    sat: 'Sábado',
    sun: 'Domingo',
    areas: {
      curb: 'No estacionamento',
      aut: 'Drive-Thru',
      ealm: 'Na mesa',
      mop: 'No balcão',
      dlv: 'McDelivery',
    },
    time: {
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    toast: {
      clipboard: 'Copiado para a área de transferência',
    },
    o: 'ou',
    freeShippingLabel: 'Frete grátis',
    free: 'Grátis',
    offer: 'Oferta',
  },
  home: {
    title: 'Início',
    products: 'Produtos',
    categories: 'Categorias',
    search: 'Localizar um endereço',
    searchButton: 'Começar',
    searchTitle: 'Como você quer seu pedido hoje?',
    searchSubtitle:
      'Selecione {pickup} se for retirar seu pedido ou McDelivery se preferir que ele seja enviado para um endereço',
    searchLocateMe: 'Usar minha localização atual',
    smartcards: {
      multiple: {
        title: 'Você tem vários pedidos em andamento',
        secondary: 'Ver pedidos',
      },
      pending: {
        title: 'Pedido com pagamento pendente',
        secondary: 'Ver detalhes',
        primary: 'Pagar',
      },
      pendingPaymentCounter: {
        title: 'Pagar no balcão',
        secondary: 'Ver detalhes',
        primary: 'Cheguei',
      },
      afterPayment: {
        pretitle: "Ir para o McDonald's",
        title: 'Pedido em andamento',
        secondary: 'Ver detalhes',
        primary: 'Cheguei',
      },
      advanceSale3days: {
        title: 'Em breve chegará o dia da sua entrega',
        secondary: 'Saber mais',
      },
      advanceSaleInDate: {
        title: 'Pedido pronto para ser entregue',
        secondary: 'Saber mais',
        primary: 'Receber meu pedido',
      },
      accepted: {
        title: 'Seu pedido foi aceito',
        secondary: 'Ver detalhes',
      },
      acceptedPaymentCounter: {
        title: 'Pagar no balcão',
        secondary: 'Ver detalhes',
      },
      preparing: {
        title: 'O pedido está sendo preparado',
        secondary: 'Ver detalhes',
      },
      delivering: {
        title: 'Seu pedido está a caminho',
        secondary: 'Ver detalhes',
        primary: 'Seguir meu pedido',
      },
      ready: {
        title: 'Pedido pronto para entrega',
        secondary: 'Ver detalhes',
      },
      delivered: {
        title: 'Seu pedido foi entregue',
        secondary: 'Ver detalhes',
        primary: 'Repeat order',
      },
    },
  },
  sidebar: {
    title: {
      normal: 'Aproveite a',
      bold: 'melhor experiência',
    },
  },
  onboarding: {
    next: 'Continuar',
    stepRestaurant: {
      title: 'Escolha como você prefere!',
      description:
        '<ul><li><b>McDelivery</b> peça e receba em qualquer lugar </li><li><b>{pickup}</b> encontre um restaurante para retirar o seu pedido</li></ul>',
    },
    stepRestaurantNoDlv: {
      title: 'Escolha como você prefere!',
      description:
        '<ul><li><b>{pickup}</b> encontre um restaurante para retirar o seu pedido</li></ul>',
    },
    stepMOP: {
      title: '{pickup}',
      description: 'Selecione {pickup} se for retirar seu pedido',
    },
    stepDLV: {
      title: 'McDelivery',
      description: 'Selecione McDelivery se preferir que ele seja enviado para um endereço',
    },
    stepCart: {
      title: 'Veja o seu pedido aqui',
      description: 'Encontre todos os produtos que escolheu e conclua a sua compra',
    },
    stepAccount: {
      title: 'Descubra "Minha Conta"',
      description: 'Complete seu perfil. Revise suas preferências e garanta uma experiência única!',
    },
    stepQR: {
      título: 'Informe seu McID',
      description: "Escaneie o código QR para se identificar rapidamente, e unir-se ao McDonald's",
    },
  },
  dataOnboarding: {
    emailValidated: 'E-mail validado com sucesso',
    succeeded: 'Registro bem-sucedido',
  },
  profile: {
    title: 'Minha conta',
    account: 'Conta',
    downloadApp: 'Baixe o App',
    help: 'Ajuda',
    sections: {
      personalData: 'Dados pessoais',
      fav: 'Favoritos',
      addressSaved: 'Endereços salvos',
      favRestaurants: 'Restaurantes favoritos',
      favProducts: 'Produtos favoritos',
      historyOrders: 'Histórico de pedidos',
      orderDetail: 'Detalhe do pedido',
      paymentMethods: 'Métodos de pagamento',
      signInSecurity: 'Login e Segurança',
      manageDevices: 'Gerenciar dispositivos',
      billData: 'Dados de faturamento',
      notification: 'Notificações',
      config: 'Configuração',
      logout: 'Sair',
    },
    notLogged: {
      title: 'Damos as boas-vindas a você para <b>McDonald’s</b>',
      subtitle: 'Faça login para aproveitar nossos produtos, benefícios e muito mais.',
    },
    subSections: {
      personalData: {
        fullName: 'Nome e sobrenome',
        email: 'Endereço de e-mail',
        phone: 'Telefone',
        password: 'Senha',
        birthday: 'Data de nascimento',
        identityCard: 'Documento de identidade',
        dataNotProvide: 'Dados não fornecidos',
        formHeaders: {
          fullName: {
            title: 'Nome e sobrenome',
            subtitle: 'Como gostaria que o chamássemos?',
            button: 'Salvar',
          },
          phone: {
            title: 'Número de telefone',
            subtitle:
              'Para garantir que somente você tenha acesso à sua conta, digite seu número de telefone',
            button: 'Salvar',
            buttonAlt: 'Validar e salvar',
            verified: 'Telefone validado',
            notVerified: 'Telefone não validado',
          },
          birthday: {
            title: 'Data de nascimento',
            subtitle:
              'Digite sua data de nascimento. Dessa forma, podemos garantir a segurança de suas informações.',
            button: 'Continuar',
          },
          identityCard: {
            title: 'Carteira de identidade',
            subtitle:
              'Digite seu número de identificação. Dessa forma, podemos garantir a segurança de suas informações.',
            button: 'Continuar',
          },
        },
      },
      manageDevices: {
        text: 'Você acessou sua conta a partir destes dispositivos. Se não reconhecer algum, pode encerrar a sessão ou alterar os dados de acesso',
        thisDevice: 'Neste dispositivo',
        logout: 'Sair',
        logoutAll: 'Encerrar todas as sessões',
        closeModals: {
          title: 'Deseja encerrar esta sessão?',
          time: '{name} - {appName}{location}, {timeAgo}',
          btnPrimary: 'Sair',
          btnSecondary: 'Cancelar',
        },
        closeAllModals: {
          title: 'Deseja encerrar todas as sessões abertas?',
          body: 'Todas as sessões serão encerradas, menos a deste dispositivo',
          btnPrimary: 'Sair de todas sessões',
          btnSecondary: 'Cancelar',
        },
        toast: {
          success: 'Sessão encerrada com sucesso',
          successAll: 'Sessões encerradas com sucesso',
          error: 'Ocorreu um erro. Por favor, tente novamente',
        },
        errorModal: {
          title: 'Ocorreu um erro ao tentar remover este dispositivo',
          titleAll: 'Ocorreu um erro ao tentar remover todos os dispositivos',
          body: 'Verifique sua conexão e tente novamente',
          bodyRetry: 'Você pode tentar novamente ou cancelar',
          button: 'Tentar novamente',
        },
      },
    },
    VError: {
      required: 'Campo obrigatório',
      customPattern: 'O campo não está formatado corretamente',
      strongPassword:
        'A senha deve conter um mínimo de 8 caracteres, 1 letra maiúscula, 1 número e 1 caractere especial,',
      passwordMatch: 'As senhas devem ser iguais.',
      email: 'Email inválido',
      nameValidation: 'Nome inválido. O nome válido contém apenas letras, traços (-) e espaços',
      ageValidation: 'Você deve ter pelo menos {minAge} anos de idade para usar o aplicativo.',
      minLength: 'O campo não atende ao número mínimo de caracteres',
      maxLength: 'O campo não atende ao número máximo de caracteres',
      cpfValidation: 'O documento não está formatado corretamente',
      cpfVerified: 'Número incorreto',
      phoneValidation: 'O número não está no formato correto',
      invalidAge: 'Não atende à idade requerida',
      duplicatePhone: 'Telefone já registrado',
    },
    inputs: {
      dropdownPlaceholder: 'Selecione uma',
      documentNumberPlaceholder: 'Numéro de document',
      phoneNumberPlaceholder: 'Número de telefone',
      labelName: 'Nome',
      labelLastName: 'Sobrenome',
      labelPrefix: 'Prefixo',
      labelSufix: 'Número',
      labelCpfType: 'Tipo de documento',
      labelCpf: 'Documento',
    },
    tooltipProfile: {
      greeting: 'Olá',
    },
  },
  productDetail: {
    customization: {
      title: 'Personalize seu produto',
      customizingTitle: 'Como você deseja personalizar?',
      extra: 'Extra:',
      actions: {
        save: 'Salvar',
        customize: 'Personalizar',
        select: 'Selecionar',
        change: 'Alterar',
        add: 'Adicionar',
      },
      chooseOne: 'Escolha um ',
      mandatory: '(Obrigatório)',
    },
    sizes: {
      title: 'Selecione um tamanho',
      types: {
        SMALL: {
          letter: 'P',
          title: 'Pequeno',
        },
        MEDIUM: {
          letter: 'M',
          title: 'Médio',
        },
        LARGE: {
          letter: 'G',
          title: 'Grande',
        },
      },
    },
    actions: {
      payNow: 'Pague agora',
      addToCart: 'Adicionar ao carrinho',
      save: 'Salvar',
    },
    alerts: {
      outdaypart: 'Este produto não está disponível no momento',
      mcDia: {
        expired: {
          título: 'Produto Gran Día fora do prazo',
          content: 'Este produto não está mais disponível',
        },
      },
      notAvailableAreas: {
        title: 'Zona de entrega não disponível: {value}',
        titleMultiple: 'Zonas de entrega não disponíveis: {value}',
      },
      mismatching: {
        NO_RESTAURANT_SELECTED: 'Você não tem nenhum restaurante selecionado',
        MISMATCHING_AREA: {
          MOP: 'Este produto está disponível apenas para retirada local',
          DLV: 'Este produto está disponível somente para entrega em domicílio',
        },
        MISMATCHING_RESTAURANT: 'Este produto não está disponível em seu restaurante',
        MISMATCHING_AREA_RESTAURANT: 'Este produto não está disponível em seu restaurante',
      },
    },
    mismatching: {
      alerts: {
        NO_RESTAURANT_SELECTED: {
          título: 'Você não tem nenhum restaurante selecionado',
          body: 'Este produto está associado ao restaurante {restaurantName}',
          accept: 'Continue',
          cancel: 'Selecione outro restaurante',
        },
        MISMATCHING_AREA: {
          titleMOP: 'Este produto está disponível somente para retirada local',
          titleDLV: 'Este produto está disponível somente para entrega em domicílio',
          body: '',
          accept: 'Selecione outro restaurante',
          cancel: '',
        },
        MISMATCHING_RESTAURANT: {
          title: 'Esse produto não está disponível em seu restaurante',
          body: '',
          accept: 'Pesquisar em meu restaurante',
          cancel: 'Selecione outro restaurante',
        },
        MISMATCHING_AREA_RESTAURANT: {
          title: 'Esse produto não está disponível em seu restaurante',
          body: '',
          accept: 'Continuar comprando',
          cancel: 'Selecione outro restaurante',
        },
        notFoundProductOnCatalog: {
          title: 'Esse produto não pôde ser encontrado em seu restaurante',
          body: '',
          accept: 'Ver catálogo',
        },
      },
    },
  },
  productDetailStatic: {
    menu: 'Cardapio',
    moreInfo: 'Mais informações',
    nutritional: {
      title: 'Informação nutricional',
      type: 'Informação nutricional',
      byProduct: 'Por Porção',
      idr: 'VD',
      empty: 'Este produto não possui informações nutricionais',
      items: {
        weight: 'Peso',
        calories: 'Calorias (Kcal)',
        sugarTotals: 'Açúcares totais',
        sugarAdded: 'Açúcares adicionados',
        lipids: 'Gordura total',
        saturatedFats: 'Gorduras saturadas',
        transFats: 'Gordura trans',
        fiber: 'Fibra alimentar',
        carbohydrates: 'Carboidratos',
        proteins: 'Proteínas',
        sodium: 'Sódio',
      },
    },
    allergens: {
      title: 'Informações sobre alérgenos',
      tableTitle: 'Este produto contém',
      empty: 'Este produto não contém alérgenos',
      items: {
        twilight: 'Corante amarelo crepúsculo',
        allura: 'Corante vermelho allura',
        fish: 'Peixe',
        milk: 'Leite',
        soy: 'Soja',
        almonds: 'Amêndoas',
        lactose: 'Actose',
        pepper: 'Pimenta',
        grain: 'Trigo',
        tartrazine: 'Corante amarelo tartrazina',
        egg: 'Ovo',
        gluten: 'Glúten',
        walnuts: 'Nozes',
        sulphites: 'Sulfitos',
        cereals: 'Cereais',
        mustard: 'Mostarda',
        nuts: 'Oleagenosas',
        sesame: 'Gergelim',
        crustaceans: 'Crustáceos',
        peanut: 'Amendoim',
        Atex: 'Atex Natural',
        wheat: 'Trigo, aveia, cevada e centeio',
        hazelnuts: 'Avelãs',
        cashewnuts: 'Castanha-de-caju',
        brazilnuts: 'Castanha-do-Brasil',
        macadamias: 'Macadâmias',
        pecans: 'Pecãs',
        pistachios: 'Pistaches',
        pinoli: 'Pinoli',
        chestnuts: 'Castanhas',
      },
    },
  },
  shoppingCart: {
    title: 'Meu pedido',
    products: 'Produtos',
    total: 'Total',
    subtotal: 'Subtotal',
    shipping: 'Envio',
    serviceFee: {
      name: 'Taxa de serviço',
      info: 'Taxa que cobre custos operacionais da plataforma e o acompanhamento de pedidos',
    },
    tip: 'Gorjeta',
    tax: 'Impostos',
    points: 'Pontos consumidos',
    extras: 'Extras',
    summary: 'Resumo do seu pedido',
    empty: {
      title: 'Você ainda não adicionou nenhum produto',
      text: 'Escolha o que quiser em nosso cardápio ou em nossas ofertas e saboreie-o em casa ou no restaurante McDonald’s de sua preferência',
    },
    suggestive: {
      title: 'Gostaria de acrescentar algo mais?',
    },
    actions: {
      back: 'Continuar comprando',
      next: 'Próximo',
      clear: 'Limpar',
      edit: 'Editar',
      seeCart: 'Ver carrinho',
    },
    promotions: {
      addPromo: {
        title: 'Códigos de desconto e promoções',
        add: 'Adicionar',
        prefix: {
          coupon: 'Código',
          employee: 'Desconto para funcionários',
        },
        terms: {
          prefix: 'Para mais informações consulte os',
          link: 'termos e condições',
          sufix: 'do código de desconto.',
        },
      },
      modal: {
        title: 'Descontos e promoções',
        disclaimer: 'Lembre-se de que descontos e promoções não podem ser combinados entre si',
        fields: {
          coupon: {
            label: 'Código',
          },
          employee: 'Desconto para funcionário',
        },
        codeError: 'Lamentamos. O código de desconto é inválido',
      },
    },
    alerts: {
      delete: {
        title: 'Deseja remover este produto?',
        body: 'Ao excluí-lo, qualquer personalização que você tenha feito também será perdida.',
        cancel: 'Cancelar',
        accept: 'Excluir',
      },
      login: {
        title: 'Você deve estar registrado para continuar',
        body: 'Clique em ingressar para aproveitar a experiência completa e muitos outros benefícios.',
        cancel: 'Não agora',
        accept: 'Ingressar',
      },
      clear: {
        title: 'Você removerá todos os produtos de seu carrinho de compras',
        body: 'Tem certeza?, todos os itens atualmente em seu carrinho de compras serão esvaziados',
        cancel: 'Cancelar',
        accept: 'Esvaziar',
      },
      cancelOrderConfirmation: {
        title: '¿Estás seguro que deseas cancelar tu pedido?',
        accept: 'Cancelar Pedido',
        body: '¡No hay problema! Tu dinero sera reintegrado en 1 o 2 dias hábiles segun la entidad bancaria.',
        cancel: 'Volver',
      },
      deletePromotedConfirmation: {
        title: 'Você quer removê-lo?',
        body: 'Isto eliminará todos os produtos que correspondem à promoção.',
        accept: 'Eliminar',
        cancel: 'Cancelar',
      },
    },
    toast: {
      orderMcdia: 'Esta promoção não é compatível com os produtos do seu pedido',
    },
  },
  checkout: {
    title: 'Detalhes do pagamento',
    checkoutAlert: 'Mensagem',
    actions: {
      pay: 'Pagar',
    },
    sections: {
      address: {
        title: 'Endereço de entrega',
      },
      tip: {
        title: 'Dar gorjeta ao seu motorista de entregas',
        description:
          'Essa gorjeta é voluntária e é dada ao seu motorista de entrega em reconhecimento ao seu trabalho.',
        chips: {
          custom_amount: 'Outra',
          custom_percent: 'Outro',
        },
        toast: {
          max: 'A porcentagem máxima de gorjeta é {max} %',
        },
      },
      paymentMethods: {
        title: 'Método de pagamento',
        add: 'Adicionar novo cartão',
        modify: 'Modificar cartões',
        methodTypes: {
          credit: 'Crédito',
          debit: 'Débito',
          inPerson: 'No balcão',
          MERCADO_PAGO_CHECKOUT_PRO: 'Dinheiro na conta',
        },
        extraMethods: {
          MERCADO_PAGO_CHECKOUT_PRO: 'Mercado Pago',
        },
        expired: 'Expirada',
        toast: {
          cardSave: {
            success: 'Cartão salvo corretamente',
            error: 'Não foi possível salvar o cartão',
          },
          yappy:
            'Lembre-se de que ao fazer seu pedido pelo Yappy, ele não pode ser cancelado em nosso aplicativo.',
        },
        modifyCards: {
          disclaimer: 'Lembre-se de que você pode armazenar até um máximo de cartões de {value}.',
          actions: {
            add: 'Adicionar cartão',
          },
          expiration: 'Validade',
          alerts: {
            deleteCard: {
              title: 'Você quer apagar este cartão?',
              accept: 'Excluir',
              cancel: 'Cancelar',
            },
          },
        },
        transition: {
          title: 'Recebemos seu pedido :)',
          subtitle: 'Aguardando confirmação de pagamento.',
        },
        exclusivePayment:
          'Observe que somente o método de pagamento selecionado abaixo está disponível para esta promoção.',
      },
      fiscal: {
        título: 'Dados de faturamento',
        filled: {
          delete: 'Não quero fatura',
          action: 'Alterar',
        },
        unfilled: {
          subtitle: 'Deseja solicitar uma fatura?',
          subtitleMandatory:
            'Nas compras acima de {price} é obrigatório inserir seus datos na nota fiscal.',
          subtitleMandatoryAlways: 'A nota fiscal é obrigatória',
          action: 'Solicitar',
        },
        modal: {
          action: 'Confirmar',
          checkbox: 'Lembrar para compras futuras',
        },
        alert: {
          mandatory: {
            title: 'Insira seus dados para gerar a fatura e completar a compra',
            bodyMandatoryAlways: 'Dados obrigatórios',
            bodyMandatory: 'Dados obrigatórios nas compras de {price} sou mais',
            accept: 'Aceitar',
            cancel: '',
          },
        },
      },
    },
    paymentResult: {
      yuno: {
        SUCCESS: {
          MOP: {
            title: 'Pagamento realizado com sucesso',
            description: 'Quando estiver no restaurante, escolha uma opção para retirar seu pedido',
            actions: {
              accept: 'Opções para retirar',
              acceptMcdia: 'Ver meus pedidos',
              alternative: 'Escolher depois',
            },
          },
          DLV: {
            title: 'Pagamento realizado com sucesso',
            description: 'O pedido está sendo preparado. Em breve você receberá no seu endereço.',
            actions: {
              accept: 'Acompanhar entrega',
              acceptMcdia: 'Voltar ao início',
              alternative: 'Detalhes do pedido',
            },
          },
        },
        ERROR: {
          paymentDidNotHappen: {
            title: 'Pagamento não realizado',
            description:
              'Houve uma falha no pagamento. Tente novamente ou escolha outro cartão. Se o problema persisitir entre em contato com o banco.',
            actions: {
              accept: 'Tentar novamente',
              alternative: 'Escolher ou inserir outro cartão',
            },
          },
          genericError: {
            title: 'Algo deu errado 🙁',
            description:
              'Verifique se os dados foram digitados corretamente. Você pode tentar novamente ou escolher outro cartão. Se o problema persistir entre em contato com o banco.',
            actions: {
              accept: 'Escolher ou inserir outro cartão',
              alternative: 'Tentar novamente',
            },
          },
        },
      },
      legacy: {
        success: {
          title: 'Seu pagamento foi bem-sucedido',
          description:
            'Siga as instruções na próxima tela e você poderá retirar seu pedido como desejar.',
          actions: {
            accept: 'Entendido',
            acceptMcdia: 'Ver meus pedidos',
          },
        },
        restaurantOrderError: {
          title: 'Lamentamos! Seu pedido não pôde ser criado',
          description: 'Ocorreu um erro ao criar o pedido no restaurante.',
          actions: {
            accept: 'Ver pedido',
          },
        },
        paymentError: {
          title: 'Lamentamos! Seu pagamento não pôde ser feito',
          description:
            'Houve um problema ao processar a transação. Tente novamente ou escolha outro método de pagamento.',
          actions: {
            accept: 'Ver pedido',
            alternative: 'Escolha outra forma de pagamento',
          },
        },
      },
    },
  },
  pickupMethods: {
    farAway: {
      title: 'Você está longe do restaurante',
      subtitle:
        'Em alguns minutos, seu pedido estará pronto. Tem certeza de que deseja escolher uma opção de entrega e iniciar a preparação?',
      actions: {
        continue: 'Opções de entrega',
        imOnWay: 'Estou a caminho',
      },
    },
    areaSelection: {
      backTitle: 'Métodos de saque',
      info: 'A preparação do pedido começa aqui. Em alguns minutos, seu pedido estará pronto',
      areas: {
        title: 'Como gostaria de retirar seu pedido?',
        MOP: {
          title: 'No balcão',
          description: 'Ir para a área de entrega',
          step: {
            title: 'Como você prefere o seu pedido?',
            subtitle: 'Escolha a opção que você deseja para que possamos preparar',
            type: {
              EATIN: 'Comer no restaurante',
              TAKEOUT: 'Para levar',
            },
          },
          area: {
            value: 'Número',
            title: 'Informe o número do seu pedido na área de entrega',
            titleDualPoint: 'Retire seu pedido quando seu número aparecer no painel',
            titleInPerson: 'Vá até o balcão para pagar seu pedido',
            subtitle: 'Estamos preparando seu pedido',
            subtitleInPerson: 'A preparação do pedido será iniciada após a conclusão do pagamento.',
            alerts: {
              noOrderNumber: {
                title: 'Não foi possível obter o número de retirada',
                accept: 'Ir para o detalhe do pedido',
                body: '',
                cancel: 'Cancelar',
              },
            },
          },
        },
        AUT: {
          title: 'Drive-Thru',
          description: 'Vá até o alto-falante e anuncie-se',
          step: {
            title: 'Seu pedido será entregue pela Drive-Thru',
            subtitle:
              'A preparação será iniciada após a confirmação do método de retirada e o pedido não poderá ser cancelado.',
            action: 'Confirmar',
          },
          area: {
            value: 'Número',
            title: 'Vá até o alto-falante para se anunciar e pegar seu pedido',
            subtitle: '',
          },
        },
        EALM: {
          title: 'Na mesa',
          description: 'Levamos o pedido à sua mesa',
          step: {
            title: 'Escreva o número de sua mesa',
            subtitle:
              'A preparação será iniciada após a confirmação do método de retirada e o pedido não poderá ser cancelado',
            help: 'Você o verá preso em uma das extremidades',
            action: 'Confirmar',
          },
          area: {
            value: 'Tabela',
            title: 'Agora tudo o que resta é esperar!',
            subtitle: 'Em breve você receberá seu pedido pronto para ser apreciado',
          },
        },
        CURB: {
          title: 'No estacionamento',
          description: 'Entregamos em sua garagem',
          step: {
            title: 'Digite o número de sua garagem',
            subtitle:
              'A preparação será iniciada quando você confirmar o método de coleta e o pedido não poderá ser cancelado',
            help: 'Encontre os depósitos marcados para que possamos entregar seu pedido',
            helpLink: 'Os depósitos estão ocupados?',
            action: 'Confirmar',
          },
          area: {
            value: 'Garagem',
            title: 'Agora tudo o que resta é esperar!',
            subtitle: 'Em breve você receberá seu pedido pronto para ser apreciado',
          },
        },
        disabled: {
          title: 'O serviço {areaTranslated} não está disponível para este pedido.',
          subtitle: 'Escolha outra opção de entrega',
        },
      },
      formErrors: {
        required: 'O valor é obrigatório',
        minValue: 'O valor mínimo é {minimum}',
        maxValue: 'O valor máximo é {maximum}',
      },
    },
  },
  errors: {
    page: {
      title: 'Ooops...',
      subtitle: 'Algo deu errado',
      description: 'Parece que a página que você está procurando não existe',
    },
    toast: {
      geo: {
        denied:
          'A permissão de geolocalização foi negada. Ative-a se quiser usar o botão para localizar você',
        unsupported: 'A geolocalização não é suportada por este navegador',
        geocoderError: 'Ocorreu um erro ao obter o endereço',
        unexpectedError: 'Ocorreu um erro inesperado. Tente novamente em alguns instantes',
      },
      generic: 'Desculpe, ocorreu um erro. Tente novamente',
    },
    alerts: {
      outOfDayPart: {
        title: 'Produto fora do horário de funcionamento. Não se esqueça de cancelar seu pedido',
        body: 'Seu pedido não pode ser entregue, porque um dos produtos está fora do horário. Uma vez cancelado, seu dinheiro será reembolsado em 1 ou 2 dias úteis',
        accept: 'Cancelar pedido',
        cancel: '',
      },
      orderMcdiaOutOfDates: {
        título: "McDonald's",
        body: 'O prazo expirou, o pedido não pode ser retirado',
        accept: 'OK',
        cancel: '',
      },
      orderNotExist: {
        title: 'O pedido não existe',
        body: 'O pedido solicitado não pode ser encontrado',
        accept: 'Voltar para produtos',
        cancel: 'Cancelar',
      },
      orderAlreadyPaid: {
        title: 'O pedido já foi pago',
        accept: 'Aceitar',
        body: '',
        cancel: 'Cancel',
      },
      pickup_unhandled: {
        title: 'O método de retirada do seu pedido não pôde ser definido',
        body: '',
        accept: 'Tente novamente',
        cancel: 'Cancelar',
      },
      pickup_noRestaurantsOpened: {
        título: 'Restaurante fechado',
        body: 'Desculpe, o restaurante está fechado ou prestes a fechar e não está mais aceitando pedidos. Você poderá retirar seu pedido quando ele estiver aberto novamente',
        accept: 'Continuar',
        cancel: 'Cancel',
      },
      pickup_serviceNumberEmpty: {
        title: 'Aconteceu algo inesperado',
        body: 'Tivemos um problema ao carregar o número que você inseriu. Por favor, tente novamente',
        accept: 'Tentar novamente',
        cancel: '',
      },
      pickup_anyPickupAreaForThisOrderIsAvailable: {
        title: 'O restaurante já não está disponível',
        body: 'Você pode cancelar o pedido para obter o reembolso e escolher outra opção. Pedimos desculpas!',
        accept: 'Ir ao meu pedido',
        cancel: '',
      },
      pickup_orderPickupNotAvailable: {
        title: 'O restaurante já não está disponível',
        body: 'Você pode cancelar o pedido para obter o reembolso e escolher outra opção. Pedimos desculpas!',
        accept: 'Ir ao meu pedido',
        cancel: '',
      },
      pickup_areaNotAvailable: {
        title: 'O restaurante já não está disponível',
        body: 'Você pode cancelar o pedido para obter o reembolso e escolher outra opção. Pedimos desculpas!',
        accept: 'Ir ao meu pedido',
        cancel: '',
      },
      generic: {
        title: 'Ocorreu um erro inesperado',
        accept: 'Aceitar',
        body: 'Por favor, tente novamente após alguns minutos',
        cancel: '',
      },
      tokenExpired: {
        title: 'Information',
        body: 'Your time to place the order has expired, you must log in again to finish the order.',
        cancel: '',
      },
      noCoverage: {
        title: 'McDelivery não está disponível no momento',
        body: 'Enquanto isso, você pode fazer um pedido e retirar no restaurante mais próximo com Peça e Retire',
        accept: 'Peça e Retire',
        cancel: 'Tentar mais tarde',
      },
      noRestaurantsOpened: {
        title: 'Desculpe, o restaurante está fechado',
        body: 'Você pode escolher outro endereço ou ver se há um restaurante próximo para retirar seu pedido',
        accept: 'Search for restaurants nearby',
        cancel: 'Cancelar',
      },
      noRiders: {
        title: 'Nossos entregadores estão ocupados',
        body: 'Você pode tentar mais tarde ou fazer um pedido e retirar com Peça e Retire',
        accept: 'Peça e Retire',
        cancel: 'Tentar mais tarde',
      },
      productNotAvailable: {
        title: 'A promoção já acabou!',
        body: 'Altere os produtos do seu pedido para continuar com a compra',
        accept: 'Voltar ao meu pedido',
        cancel: '',
      },
      orderErrorPrice: {
        title: 'Seu pedido não pôde ser processado',
        accept: 'Aceitar',
        body: 'Por favor, tente novamente após alguns minutos',
        cancel: 'Cancelar',
      },
      platformNotValidForPromo: {
        title: 'Esta promoção não é compatível',
        body: 'Por favor, selecione outro método de pagamento para concluir a compra',
        accept: 'Escolher outro método de pagamento',
        cancel: '',
      },
      orderInProgressMultiple: {
        title: 'Você atingiu o número máximo de pedidos em andamento',
        accept: 'Meus pedidos',
        body: 'Até que você retire pelo menos um de seus pedidos atuais, não será possível iniciar outra compra',
        cancel: 'Cancelar',
      },
      orderPickupNotAvailable: {
        title: 'O restaurante selecionado em um pedido anterior está indisponível no momento',
        accept: 'Aceitar',
        body: 'Por favor, selecione um novo restaurante',
        cancel: 'Cancelar',
      },
      orderGeneric: {
        title: 'Seu pedido não pôde ser processado',
        accept: 'Aceitar',
        body: 'Por favor, tente novamente após alguns minutos',
        cancel: 'Cancelar',
      },
      orderInProcess: {
        title: 'Error',
        body: 'Order is on its way',
        accept: 'Aceitar',
        cancel: 'Cancelar',
      },
      promotionNotFound: {
        title: 'A promoção já acabou!',
        body: 'Altere os produtos do seu pedido para continuar com a compra',
        accept: 'Voltar ao meu pedido',
        cancel: '',
      },
      orderProductMismatch: {
        title: 'Erro ao criar seu pedido',
        body: 'Ocorreu um erro ao criar seu pedido e ele não pode ser feito',
        accept: 'Aceitar',
        cancel: '',
      },
      countryNotValid: {
        title: 'Ocorreu um erro com o pagamento',
        body: 'O país selecionado não corresponde ao país em que o pedido foi feito. Altere o país para prosseguir com o pagamento',
        accept: 'Aceitar',
        cancel: '',
      },
      paymentPendingApproval: {
        title: 'Você já tentou efetuar um pagamento para esse pedido',
        body: 'O pagamento do seu pedido está pendente de aprovação. Verifique o status do seu pedido em alguns minutos',
        accept: 'Aceitar',
        cancel: '',
      },
      promotionNotAvailable: {
        title: 'A promoção não está mais disponível e não pode ser aplicada a esse pedido',
        body: '',
        accept: 'Aceitar',
        cancel: '',
      },
      orderMcdia: {
        title: 'Sua compra não pode ser realizada!',
        body: 'Os produtos do McDia Feliz não podem ser comprados com outros produtos. Para continuar com a compra, você deve remover os produtos que não correspondem à campanha',
        accept: 'Remover produtos',
        cancel: '',
      },
      fiscalFieldsRequired: {
        title: 'Insira suas informações de cobrança para concluir a compra',
        body: 'Obrigatório para compras maiores ou iguais a {value}',
        accept: 'OK',
        cancel: '',
      },
      antifraudTemporalBlock: {
        título: 'Bloqueio temporário',
        body: 'Para a segurança de sua conta, estamos bloqueando temporariamente a função de pagamento',
        accept: 'Voltar à página inicial',
        cancel: '',
      },
      antifraudLimitTransactionsReached: {
        title: 'Limite diário de transações atingido',
        body: 'Identificamos um número excessivo de transações de pagamento consecutivas. Sua conta foi temporariamente bloqueada por motivos de segurança',
        accept: 'Voltar à página inicial',
        cancel: '',
      },
      phoneValidationRequired: {
        title: 'Valide seu telefone',
        body: 'Você precisa validar seu telefone para fazer um pedido',
        accept: 'Aceitar',
        cancel: '',
      },
      deviceValidationRequired: {
        title: 'Valide seu dispositivo',
        body: 'Você precisa validar seu dispositivo para fazer um pedido',
        accept: 'Aceitar',
        cancel: '',
      },
      promoExclusivePaymentUsed: {
        title: 'Você já aproveitou essa promoção',
        body: 'Volte ao carrinho e altere os produtos para continuar com o pedido',
        accept: 'Voltar a meu pedido',
        cancel: '',
      },
      limitProductExclusivePayment: {
        title: 'Permitimos apenas um item de promoção por compra',
        body: 'Você pode manter outros produtos do cardápio nessa compra, mas só poderá adicionar apenas uma única vez a oferta promocionada',
        accept: 'Entendi',
        cancel: '',
      },
      limitedPromoProduct: {
        title: 'Seu pedido já inclui produtos com desconto',
        body: 'Você quer manter os descontos atuais ou aplicar o novo código?',
        accept: 'Manter descontos',
        cancel: 'Aplicar novo código',
      },
      employeeDiscount: {
        title: 'Seu pedido já tem descontos aplicados',
        body: 'Você quer substituir a promoção ou adicionar o produto sem desconto?',
        accept: 'Adicionar sem desconto',
        cancel: 'Substituir promoção',
      },
      areaNotFound: {
        title: 'O restaurante não está disponível para os produtos selecionados',
        body: 'Você pode escolher outro restaurante ou voltar ao seu pedido e mudar os produtos',
        accept: 'Voltar ao meu pedido',
        cancel: 'Alterar restaurante',
      },
      limitPriceOrder: {
        title: 'O valor total da sua compra é muito alto',
        body: 'Você atingiu o limite permitido de {value}. Por favor, verifique os produtos do carrinho',
        accept: 'Voltar ao meu pedido',
        cancel: '',
      },
      restaurantNotLoyalty: {
        title: 'Este restaurante não participa do {value}',
        body: 'Para continuar, altere os produtos do seu pedido ou escolha outro restaurante. Se fizer isso, seu carrinho será esvaziado',
        accept: 'Voltar ao meu pedido',
        cancel: 'Alterar restaurante',
      },
      cumulativePromotionsNotAllowed: {
        title: 'Seu pedido já tem descontos aplicados',
        body: 'Você quer substituir a promoção ou adicionar o produto sem desconto?',
        accept: 'Adicionar sem desconto',
        cancel: 'Substituir promoção',
      },
      autoApplicablePromotionNotAllowed: {
        title: 'Seu pedido já inclui produtos com desconto',
        body: 'Você quer manter os descontos atuais ou aplicar o novo código?',
        accept: 'Manter descontos',
        cancel: 'Aplicar novo código',
      },
    },
  },
  selectRestaurant: {
    DLV: {
      noNumber: 'Sem número',
      addAddressError: 'É necessário preencher este campo',
      additionlInfoError: 'É necessário preencher este campo',
      completeDirection: 'Confirme seu endereço',
      street: 'Rua',
      number: 'Número',
      additionalInfo: 'Informações adicionais para o entregador',
      additionalInfoPlaceholder: 'Casa, apartamento...',
      additionalInfoDeliveryPlaceholder: 'Ajude-nos a entregar o seu pedido',
      willSendTo: 'Enviaremos a sua encomenda para',
      confirmLocation: 'Confirmar localização',
      favorite: {
        saveTitle: 'Salvar como endereço favorito',
        formTitle: 'Deseja dar um nome a ele?',
        fields: {
          alias: {
            label: '*Alias',
            required: 'O alias do endereço é obrigatório',
          },
        },
      },
    },
    MOP: {
      findYourMcDonalds: "Encontre o seu McDonald's",
      seeList: 'Ver lista',
      seeMap: 'Ver mapa',
      emptyList: 'Não encontramos nenhum restaurante próximo nesse local.',
    },
    map: {
      adjustPin: 'Ajuste o pino na posição correta',
    },
    lupap: {
      city: 'Cidade',
      form: {
        address: 'Endereço',
        complement: 'Prédio, torre, piso ...',
      },
      continue: 'Continuar',
    },
  },
  alertDialog: {
    mcDeliveryError: {
      title: 'Desculpe, ainda não temos serviço McDelivery em sua área',
      body: 'Estamos trabalhando para poder oferecer a você, mas por enquanto escolha outro endereço ou veja se há algum restaurante próximo para retirar seu pedido',
      btnPrimary: 'Pesquisar restaurantes próximos',
      btnSecondary: 'Encontre outro endereço',
    },
    ridersError: {
      title: 'Desculpe, no momento não há motoristas de entrega disponíveis em sua área',
      body: 'Você pode escolher outro endereço ou ver se há algum restaurante próximo para retirar seu pedido',
      btnPrimary: 'Pesquisar restaurantes próximos',
      btnSecondary: 'Encontre outro endereço',
    },
    restaurantClosedError: {
      title: 'Desculpe, o restaurante está fechado',
      body: 'Você pode escolher outro endereço ou ver se há algum restaurante próximo para retirar seu pedido',
      btnPrimary: 'Pesquisar restaurantes próximos',
      btnSecondary: 'Encontre outro endereço',
    },
    genericError: {
      title: 'Desculpe, ocorreu um erro',
      body: 'Erro ao obter restaurantes disponíveis',
      btnPrimary: '',
      btnSecondary: '',
    },
    changeRestaurant: {
      titleMOP: 'Tem certeza de que deseja alterar o restaurante de retirada?',
      titleDLV: 'Tem certeza de que deseja alterar o restaurante de entrega?',
      body: 'Você terá que começar seu pedido novamente e selecionar no menu.',
      btnPrimary: 'Alterar restaurante',
      btnSecondary: 'Cancelar',
    },
  },
  restaurantTooltipAlert: {
    closed:
      'Lembre-se que o restaurante selecionado está fechado e não aceita pedidos neste momento',
    riders: 'Parece que não há pilotos no momento',
    farFromRestaurant:
      'Parece que você está longe do restaurante. Você sempre pode alterá-lo clicando nele.',
    farFromRestaurantOptions: {
      withoutGeo: {
        title: 'Confere se este endereço está correto',
        subtitle:
          'Se você mudar, ative a localização para ficar mais certinho.<br/><b>Quer escolher outro endereço?</b>',
      },
      withGeo: {
        title: 'A gente viu que você está longe deste restaurante!',
        subtitle: 'Quer escolher outro?',
      },
    },
    actions: {
      dontChange: 'Não, continuar',
      change: 'Sim, alterar',
    },
    farFromRestaurantWithOrder: 'Notamos que você está longe do restaurante onde fez o pedido',
    farFromRestaurantWithoutOrder:
      'Notamos que você está longe do restaurante onde fez o pedido. Você pode mudar pressionando sobre o endereço',
  },
  restaurant: {
    status: {
      closed: 'Fechado',
      nearToClose: 'Próximo a fechar',
      onlyLocal: 'Apenas pedidos no local',
    },
    detail: {
      schedule: 'Horário',
      header: {
        MOP: 'Retirar em',
        DLV: 'Enviar para',
      },
      howToArrive: 'Como chegar',
      mop: 'Métodos de retirada',
      closed: 'Fechado',
      services: {
        title: 'Serviços',
        breakfast: 'Café da manhã',
        mcCafe: 'McCafé',

        timeExtended: '24 horas',
        mcParty: 'McParty',
        playPlace: 'PlaySpace',
        parking: 'Estacionamento',
        wifi: 'WIFI',

        wheelchairAccess: 'Acessibilidade',
        dessertCenter: 'Dessert Center',
        shoppingCenter: 'Shopping Center',
      },
    },
  },
  help: {
    links: {
      faqs: {
        title: 'Alguma dúvida?',
        linkText: 'Ajuda e FAQ’s',
      },
      whatsapp: {
        title: 'Precisa de ajuda?',
        titleAlt: 'Também estamos em',
        linkText: 'Whatsapp',
      },
      sac: {
        title: 'Atendimento ao cliente',
        linkText: 'Telefone',
      },
    },
  },
  orders: {
    orderNumber: 'Número do pedido',
    delivery: {
      deliveryCode: 'Código de entrega',
      sentFrom: 'Enviado de',
      description: 'Informe o código ao entregador apenas ao receber seu pedido',
    },
    date: {
      today: 'Hoje',
      yesterday: 'Ontem',
    },
    historyOrders: {
      onGoing: 'Você não tem um pedido atual',
      finished: 'Você não tem pedidos concluídos',
    },
    status: {
      pending: 'Pagamento pendente',
      pendingCounter: 'Pagar no balcão',
      paymentDone: 'Pagamento realizado',
      paymentDoneMcdia: 'Preparando-se para o dia',
      paymentDoneMcdia3days: 'Preparando-se para o dia',
      paymentDoneMcdiaInDate: 'Pronto para entrega',
      paymentDoneMcdiaExpired: 'Prazo expirado',
      goRestaurant: 'Ir ao restaurante',
      received: 'Recebido',
      accepted: 'Aceito',
      inProgress: 'Em preparação',
      ready: 'Pronto',
      delivering: 'A caminho',
      cancelled: 'Cancelado',
      error: 'Erro no pedido',
      delivered: 'Entregue',
      finished: 'Finalizado',
      pickupExpired: 'Expirado',
    },
    areas: {
      aut: 'Entrega no Drive-Thru',
      ealm: 'Entrega na mesa',
      mop: 'Retirada no balcão',
      curb: 'Retirada no estacionamento',
    },
    buttons: {
      onGoing: 'Em andamento',
      finished: 'Finalizado',
      cancelOrder: 'Cancelar pedido',
      pay: 'Pagar',
      reorder: 'Reordenar pedido',
      rate: 'Avaliar',
      arrival: 'Alcance',
      track: 'Acompanhar pedido',
      receive: 'Receber meu pedido',
    },
    toast: {
      orderCanceled: 'Order canceled',
      orderCanceledError: 'Order could not be canceled',
      orderCanceledMOP: 'A encomenda está a ser preparada e não pode ser anulada.',
      orderCanceledDLV:
        'O entregador já está a caminho de sua casa e não é possível cancelar a entrega',
      orderUnappliedProducts:
        'A promoção foi adicionada, mas não aplica aos produtos do seu pedido',
    },
    messages: {
      yappy:
        'Se tiver dúvidas sobre o pedido realizado com Yappy, entre em contato conosco através do nosso chat',
      flexCancelledReason: {
        FLEX_CANCELLED_REFUND:
          'Seu pedido foi cancelado. Mas não se preocupe, já estamos processando o reembolso do seu dinheiro. <br/><br/>Pedimos desculpas!',
        FLEX_CANCELLED_ERR_ADDRESS:
          'Encomenda anulada. <br/><br/>Houve um problema com o endereço de envio. O dinheiro não pode ser reembolsado. Pedimos desculpa pelo incómodo.',
        FLEX_CANCELLED_ERR_INFO: 'Seu pedido foi cancelado. Pedimos desculpas!',
      },
    },
    alert: {
      receiveOrder: {
        title: 'Estamos prontos para preparar seu pedido',
        body: 'Lembre-se que é importante estar no endereço que você escolheu para receber seu pedido. Você já está em <b>{address}</b> para recebê-lo?',
        accept: 'Confirmar',
        cancel: 'Ainda não',
      },
    },
    receiveOrder: {
      texts: {
        'no-riders': {
          title: 'Desculpe, não encontramos entregadores disponíveis para o seu pedido',
          body: 'Estamos trabalhando para resolver isso o mais rápido possível. Tente solicitar o envio novamente em alguns minutos.',
        },
        'no-stock': {
          title: 'Lamentamos, não pudemos iniciar o seu pedido devido à falta de ingredientes',
          body: 'Estamos trabalhando para resolver isso o mais rápido possível. Tente solicitar o envio novamente em alguns minutos.',
        },
        'rest-closed': {
          title: 'Neste momento, o restaurante está fechado',
          body: 'Para garantir que você possa receber seu pedido, certifique-se de solicitar a entrega durante nosso horário de funcionamento.',
        },
        'generic': {
          title: 'Desculpe, algo deu errado e não foi possível iniciar seu pedido',
          body: 'Estamos trabalhando para resolver o problema o mais rápido possível. Tente solicitar a entrega novamente ou aguarde alguns minutos para tentar novamente.',
        },
      },
      actions: {
        primary: 'Tente novamente mais tarde',
        secondary: 'Ir para o meu pedido',
      },
    },
  },

  SEO: {
    restaurants: {
      title: "Encontre o McDonald's mais próximo de você",
      description:
        'Encontre o restaurante McDonald’s mais próximo, onde quer que esteja | Horários, endereços e menus para que você possa desfrutar de seus favoritos: entrega ou {pickup}.',
      city: {
        title: "Encontre o McDonald's mais próximo de você em {city}",
        description:
          'Descubra o restaurante McDonald’s mais próximo em {city}. Seu hambúrguer favorito perto de você | Horários, endereços e menus.',
        restaurant: {
          title: '{restaurant} {city}: Descubra nossas ofertas',
          description:
            'Peça seus favoritos do McDonald’s onde quer que você esteja. Peça sua comida favorita com {restaurant} {city} pelo melhor preço✓.',
          orders: {
            title: `Meus pedidos no {restaurant} {'|'} McDonald's`,
            description: `Peça em {restaurant} em {city} {'|'} Explore nosso cardápio e aproveite a comodidade de entrega ou {pickup}.`,
            area: {
              title: `⇨{area} McDonald's {city}`,
              description: `Encontre e personalize o menu que você mais gosta com acompanhamento para escolher, batatas fritas e bebida. Escolha o tamanho e peça no McDonald's pelo melhor preço✓.`,
              category: {
                title: `Peça {category} {restaurant}`,
                description: `Seu pedido do McDonald's está mais próximo do que nunca. Escolha seu {category} disponível para entrega ou {pickup} em {restaurant}.`,
                product: {
                  title: `Peça {product} {restaurant}`,
                  description: `Seu pedido do McDonald's está mais próximo do que nunca. Aproveite {product} disponível para entrega ou {pickup} em {restaurant}.`,
                },
              },
            },
          },
        },
      },
    },
    menu: {
      title: 'McDonald’s menu - Todos os produtos',
      description: `Encontre e personalize o menu que você mais gosta com acompanhamento para escolher, batatas fritas e bebida. Escolha o tamanho e peça no McDonald's pelo melhor preço✓.`,
      category: {
        title: `{category} McDonald's {'|'} Complete seu cardápio`,
        description: `Você está com fome? No McDonald's temos diferentes ofertas de {category} no McDonald's para retirar ou pedir pelo melhor preço ✓ Escolha seu menu favorito.`,
        product: {
          title: `{product} - {category} {'|'} McDonald's`,
          description: `Aproveite seu {product}, entrega ou {pickup}. 24h no McDonald's mais próximo ✓ Descubra nossas novidades e ofertas no web oficial.`,
        },
      },
    },
    orders: {
      cart: {
        title: `Revise e conclua seus pedidos {'|'} McDonald’s`,
        description: `Complete seu pedido no McDonald’s {'|'} Encontre os produtos que você deseja e prossiga para o pagamento online com facilidade e segurança {'|'} Aproveite!`,
      },
      cartProductIndex: {
        title: `Revise e conclua seus pedidos {'|'} McDonald’s`,
        description: `Revise seu pedido, você pode concluí-lo ou editá-lo facilmente {'|'} Encontre os produtos que deseja e prossiga para o pagamento online com facilidade e segurança {'|'} Aproveite!`,
      },
      checkout: {
        title: `Checkout {'|'} McDonald’s`,
        description: `Complete seu pedido {'|'} Revise sua seleção e insira suas informações. Pronto para aproveitar uma refeição deliciosa! Pagamento seguro✓`,
      },
    },

    my: {
      account: {
        title: `Meus dados pessoais {'|'} McDonald's`,
        description: `Gerencie seus dados pessoais de forma fácil e segura no McDonald's {'|'} Atualize informações de perfil, endereço e favoritos {'|'} Mantenha sua conta atualizada.`,
        section: {
          title: `{section} {'|'} McDonald's`,
          description: `Gerencie seus dados pessoais de forma fácil e segura no McDonald's {'|'} Atualize informações de perfil, endereço e favoritos {'|'} Mantenha sua conta atualizada.`,
        },
        my: {
          orders: {
            title: `Meus pedidos {'|'} McDonald's`,
            description: `Consulte seus pedidos, histórico de compras e detalhes de seus pedidos anteriores {'|'} Acompanhe suas entregas e faça um novo pedido se desejar.`,
            orderId: {
              title: `{orderId} {'|'} McDonald's`,
              description: `Consulte os detalhes do seu pedido {orderId} {'|'} Informações detalhadas sobre sua compra, status de entrega e produtos adquiridos no McDonald's.`,
            },
          },
        },
      },
    },
  },
  phone: {
    phoneValidation: {
      título: 'Número de telefone',
      subtitle:
        'Para garantir que somente você tenha acesso à sua conta, digite seu número de telefone',
      continue: 'Validar e salvar',
      duplicatePhone:
        'O número de telefone já está registrado. Por favor, insira um número diferente',
    },
    phoneVerification: {
      title: 'Valide seu telefone',
      subtitle: 'Acabamos de enviar o código de validação de seis dígitos para seu telefone',
      notSended: 'Você não recebeu o código?',
      notSendedDisclaimerCountdown: 'Você pode reaplicar em {time}.',
      notSendedDisclaimer: 'Você pode reaplicar novamente agora.',
      sendAgain: 'Enviar código novamente',
      errorCode: {
        CODE_FIELD_ERROR: 'Código incorreto',
        CODE_FIELD_ERROR_EXPIRED:
          'O código expirou.<br/>Para validar seu telefone, clique em “enviar código novamente”',
      },
    },
    alert: {
      phoneAlreadyValidated: {
        title: 'Desculpe, houve um erro no processamento',
        body: 'Por favor, tente novamente',
        accept: 'Continuar',
      },
    },
    toast: {
      generic: 'Desculpe, houve um erro. Tente novamente',
      warning: 'Aguarde {time} antes de solicitar um novo código novamente',
      success: 'Telefone verificado',
    },
    block: {
      title: 'Limite de remessas excedido',
      subtitle: 'Não foi possível validar seu telefone devido ao excesso de envios',
      tryAgainCountdown: 'Por favor, tente novamente em {time}.',
      tryAgainNow: 'Por favor, tente novamente agora.',
      action: 'Voltar ao início',
    },
  },
  device: {
    deviceVerification: {
      title: 'Valide seu dispositivo',
      subtitle:
        'Acabamos de enviar o <b>código de validação de quatro dígitos</b> para seu {email}, digite-o para continuar seu pedido.',
      notSended: 'Não recebeu o código?',
      notSendedDisclaimerCountdown:
        'Lembre-se de verificar sua pasta de spam. Você pode se inscrever novamente em {time}.',
      notSendedDisclaimer:
        'Lembre-se de verificar sua pasta de spam. Você pode se inscrever novamente agora.',
      sendAgain: 'Enviar o código de novo',
      errorCode: {
        CODE_FIELD_ERROR: 'Código errado',
        CODE_FIELD_ERROR_EXPIRED:
          'O código expirou.<br/>Para validar seu dispositivo, clique em “enviar o código de novo”',
      },
    },
    alert: {
      deviceAlreadyValidated: {
        title: 'Desculpe, houve um erro no processo.',
        body: 'Por favor, tente novamente',
        accept: 'Continuar',
      },
    },
    toast: {
      generic: 'Ocorreu um erro. Por favor, tente novamente.',
      success: 'Dispositivo validado com sucesso',
    },
    block: {
      title: 'Limite de remessas excedido',
      subtitle: 'Não foi possível validar seu dispositivo devido ao excesso de remessa.',
      tryAgainCountdown: 'Por favor, tente novamente em {time}.',
      tryAgainNow: 'Por favor, tente novamente agora.',
      action: 'Voltar ao início',
    },
  },
  email: {
    emailVerification: {
      title: 'Verifique seu e-mail',
      subtitle:
        'Acabamos de enviar o <b>código de validação de quatro dígitos</b> para seu {email}, digite-o para continuar seu pedido.',
      notSended: 'Não recebeu o código?',
      notSendedDisclaimerCountdown:
        'Lembre-se de verificar sua pasta de spam. Você pode se inscrever novamente em {time}.',
      notSendedDisclaimer:
        'Lembre-se de verificar sua pasta de spam. Você pode se inscrever novamente agora.',
      sendAgain: 'Enviar o código de novo',
      errorCode: {
        CODE_FIELD_ERROR: 'Código errado',
        CODE_FIELD_ERROR_EXPIRED:
          'O código expirou.<br/>Para validar seu e-mail, clique em “enviar o código de novo”',
      },
    },
    alert: {
      emailAlreadyValidated: {
        title: 'Desculpe, houve um erro no processo.',
        body: 'Por favor, tente novamente',
        accept: 'Continuar',
      },
      validationInProgress: {
        title: 'Tem certeza de que deseja voltar?',
        body: 'Lembre-se de que, ao fazer isso, você poderá perder os dados que inseriu até o momento',
        accept: 'Voltar',
        cancel: 'Cancelar',
      },
    },
    toast: {
      generic: 'Ocorreu um erro. Por favor, tente novamente.',
      success: 'Dados salvos com sucesso',
      successAlt: 'Correio eletrônico confirmado com sucesso',
    },
    block: {
      title: 'Limite de remessas excedido',
      subtitle: 'Não foi possível validar seu e-mail devido ao excesso de remessa.',
      tryAgainCountdown: 'Por favor, tente novamente em {time}.',
      tryAgainNow: 'Por favor, tente novamente agora.',
      action: 'Voltar ao início',
    },
  },
  requiredFields: {
    title: 'Preencha seus dados',
    subtitle:
      'Insira os detalhes que faltam para continuar com sua compra. Esses detalhes serão armazenados de forma segura e não os solicitaremos novamente',
    actions: {
      continue: 'Continuar',
    },
    alerts: {
      genericError: {
        title: 'Desculpe, houve um erro no processamento',
        body: 'Por favor, tente novamente',
        accept: 'Continuar',
      },
      duplicatePhone: {
        title: 'Telefone já registrado',
        body: 'O número de telefone já está registrado. Por favor, insira um número diferente',
        accept: 'Continuar',
      },
    },
  },
  repeatOrder: {
    success: {
      title: 'Tem certeza de que deseja repetir o pedido?',
      body: 'O pedido será criado no mesmo restaurante em que você fez o pedido. Se houver produtos em seu carrinho, eles serão substituídos pelos produtos dessa compra. Os preços dos produtos podem ter mudado',
      accept: 'Continuar',
      cancel: 'Cancelar',
    },
    genericError: {
      title: 'Oops! Não será possível repetir o pedido neste momento',
      body: 'Nesse meio tempo, dê uma olhada em nosso catálogo ou tente novamente',
      accept: 'Tentar novamente',
      cancel: 'Ver produtos',
    },
    noRiders: {
      title: 'Desculpe, no momento não há motoristas de entrega disponíveis em sua área',
      body: 'Estamos enfrentando uma alta demanda de pedidos. Por favor, tente novamente em alguns minutos.',
      accept: 'Aceitar',
      cancel: '',
    },
    mopNotAvailable: {
      title: 'Oops, não será possível fazer um novo pedido',
      body: 'Esse restaurante está indisponível no momento {pickup}. Por favor, tente novamente em alguns minutos.',
      accept: 'Aceitar',
      cancel: '',
    },
    dlvNotAvailable: {
      title: 'Oops, não será possível fazer um novo pedido',
      body: 'Este restaurante não está disponível para McDelivery no momento. Por favor, tente novamente em alguns minutos.',
      accept: 'Aceitar',
      cancel: '',
    },
    restaurantNotFound: {
      title: 'Oh não, não conseguimos encontrar seu restaurante',
      body: 'Recomendamos que você tente fazer um novo pedido em outro restaurante',
      accept: 'Aceitar',
      cancel: '',
    },
    closedRestaurant: {
      title: 'Nosso restaurante está fechado no momento',
      body: 'Não será possível repetir esse pedido. Sugerimos que você escolha outro restaurante para fazer sua compra',
      accept: 'Aceitar',
      cancel: '',
    },
    permittedAreasProducts: {
      title: 'Oh não, esse pedido não está mais disponível',
      body: 'Alguns dos produtos desse pedido não podem ser retirados por nenhum dos métodos de retirada disponíveis',
      accept: 'Aceitar',
      cancel: '',
    },
    outOfDaypartProducts: {
      title: 'Oops! Não será possível fazer um novo pedido nesse momento',
      body: 'Alguns produtos não estão dentro de sua programação de preparação. Tente refazer o pedido novamente ou inicie uma nova compra',
      accept: 'Aceitar',
      cancel: '',
    },
    notExistingProducts: {
      title: 'Oops! No momento, não será possível repetir o pedido',
      body: 'Alguns produtos dessa compra não estão mais no catálogo. Tente refazer o pedido novamente ou inicie uma nova compra',
      accept: 'Aceitar',
      cancel: '',
    },
  },
  contact: {
    title: 'Preencha o formulário com seus dados',
    inputs: {
      firstname: 'Nome',
      lastname: 'Sobrenome',
      email: 'Email',
      phone: 'Telefone',
      city: 'Cidade',
      local: 'Restaurante',
      date: 'Dia',
      time: 'Hora',
      message: 'Mensagem',
      newsletterAccepted:
        "Eu concordo em receber informações sobre as novidades e promoções do McDonald's",
      subject: {
        label: 'Assunto',
        placeholder: 'Escolha um',
        option: {
          suggestion: 'Sugestão',
          inquire: 'Consulta',
          openDoors: 'Portas abertas',
          criticism: 'Crítica',
          claim: 'Reclamação',
          praise: 'Elogio',
          personalData: 'Dados pessoais',
          other: 'Outros',
        },
      },
    },
    toast: {
      error: 'Desculpe, ocorreu um erro inesperado. Por favor, tente novamente mais tarde',
      success: 'O formulário de contato foi enviado com sucesso',
    },
    action: {
      save: 'Salvar',
    },
  },
  rating: {
    stars: {
      help: 'Escolha de 1 a 5 estrelas para avaliar seu pedido',
      new: {
        title: 'Como foi sua experiência com esse pedido?',
      },
      edit: {
        title: 'Continuar a avaliar o último pedido?',
        actions: {
          notNow: 'Não agora',
          continue: 'Continuar',
        },
      },
    },
    form: {
      rate: 'Avalie sua satisfação com:',
      anyProblem: 'Você teve algum problema com o pedido?',
      send: 'Enviar',
    },
    finalStep: {
      title: "Obrigado por escolher o McDonald's!",
      ok: {
        subtitle:
          "Valorizamos seus comentários e queremos garantir que sua experiência no McDonald's seja a melhor possível.",
      },
      ko: {
        subtitle: "Queremos garantir que sua experiência no McDonald's seja a melhor possível.",
        subtitle2:
          'Se tiver alguma dúvida ou precisar de assistência, entre em contato com a nossa central de atendimento ao cliente - estamos aqui para ajudar!',
      },
    },
    toasts: {
      NOT_FOUND: 'Desculpe, o pedido não foi encontrado',
      ALREADY_RATED: 'Esse pedido já foi avaliado',
      RATING_EXPIRED: 'Desculpe, o tempo para avaliar esse pedido expirou',
      UNKNOWN: 'Desculpe, ocorreu um erro inesperado. Por favor, tente novamente mais tarde',
    },
  },
  overrideRestaurant: {
    autoselectRestaurantAndArea: {
      title: 'Tem certeza de que deseja alterar o restaurante?',
      body: 'Se você quiser continuar, os produtos no carrinho atual serão removidos',
      accept: 'Continuar',
      cancel: 'Cancelar',
    },
    autoselectRestaurantAndAreaError: {
      title: 'Ocorreu um erro ao selecionar o restaurante',
      body: 'O restaurante selecionado não está disponível. Desculpe, tente novamente mais tarde',
      accept: 'Continuar',
      cancel: 'Cancelar',
    },
  },
  redirection: {
    error: {
      title: 'Algo deu errado',
      fallbackLink: 'Clique aqui para continuar',
    },
  },
  enrollment: {
    BirthDate: {
      title: 'Data de nascimento',
      body: 'É necessário preencher sua data de nascimento para poder verificar que nossos usuários têm a idade mínima necessária para usar o aplicativo',
      dateLabel: 'Data de nascimento',
    },
    Name: {
      title: 'Nome e sobrenome',
      body: 'Como você gostaria de ser chamado(a)?',
      label: {
        name: 'Nome',
        lastName: 'Sobrenome',
      },
    },
    Document: {
      title: 'Documento',
      body: 'Digite o número de seu documento para garantir a segurança das suas informações',
      labelCpfType: 'Tipo de documento',
      dropdownPlaceholder: 'Selecione um',
      documentLabel: 'Nº do documento',
      optInCpfLabel:
        'Concordo que o Méqui utilize meu CPF, também indicado em minhas compras nos Restaurantes McDonald´s, para oferecer produtos, serviços e ofertas personalizadas, tudo de acordo com a',
      warningTitle: 'Consulte mais informações',
      warningBody: 'Atendimento ao cliente',
    },
    Phone: {
      title: 'Número de telefone',
      body: 'Para garantir que apenas você tenha acesso à sua conta, insira seu número de telefone',
      label: {
        prefix: 'Prefixo',
        number: 'Número',
      },
    },
  },
  vouchers: {
    title: 'Você tem disponíveis',
    description: 'Você pode obter um código Minecraft se tiver os pontos necessários',
    redeemCoupon: {
      title: 'Deseja resgatar <br/><b>{value} pts.</b> para obter <br/>um código?',
      accept: 'Quero obter um código',
      cancel: 'Agora não',
    },
    appliedCoupon: {
      title: 'Parabéns! Você acaba de obter seu código Minecraft',
      useCodeInfo: 'Como usar os códigos?',
      codeExpiration: 'Seu código vence em',
      accept: 'Voltar',
    },
    codeList: {
      title: 'Ver meus códigos obtidos',
      description: 'Códigos',
      code: 'Código',
      expiration: 'Validade',
    },
    notification: {
      title: 'Somente um código pode ser ativado por conta Minecraft',
      description:
        "Resgate seus pontos de { startDate } a { endDate } ou até o estoque de { stock } unidades se esgotar. O código ativa 25 complementos (Add-ons) do McDonald's no Minecraft: Bedrock Edition",
    },
    errors: {
      generic: 'Algo não saiu como esperávamos. Tente novamente mais tarde',
      maximumPurchasesExceeded: 'Você superou o número máximo de compras de códigos Minecraft',
      noPointsAvailable:
        'Você ainda não tem pontos suficientes para obter um código. São necessários { value } pontos para conseguir',
    },
  },
  stepsReference: 'Passo {current} de {total}',
  markdown: {
    vouchers: {
      terms: termsPT,
    },
  },
}
