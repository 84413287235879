import { z } from 'zod'

export const customerMetadataDto = z.object({
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  country: z.string().optional(),
  countryProfile: z.string().optional(),
  promoInfo: z.string().optional(),
  appVersion: z.string().optional(),
  mcId: z.string().optional(),
  birthDateStr: z.string().optional(),
  phoneNumberPrefix: z.string().optional(),
  phoneNumberSufix: z.string().optional(),
  favoriteProducts: z.string().optional(),
  favoriteRestaurants: z.string().optional(),
  documentType: z.string().optional(),
  cpf: z.string().optional(),
  loyalty: z.string().optional(),
  clubVipAutomac: z.string().optional(),
})

export type CustomerMetadata = z.infer<typeof customerMetadataDto>

export const updateCustomerDto = z.object({
  checkCpf: z.boolean().optional(),
  metadata: customerMetadataDto,
})

export const customerDto = z.object({
  id: z.string(),
  email: z.string(),
  metadata: z.array(
    // TODO(?)
    z.object({
      key: z.string(),
      value: z.string(),
      verified: z.boolean(),
    })
  ),
  tags: z.array(
    z.object({
      _id: z.string(),
      value: z.string(),
    })
  ),
  deviceIdentifiers: z
    .array(
      z.object({
        deviceIdentifier: z.string(),
        verifiedAt: z.string().nullable(),
      })
    )
    .optional(),
  segments: z.array(z.string()).optional(),
})

export type Customer = z.infer<typeof customerDto>

/* IM validation */

export const requestValidateDto = z.object({
  nextValidationAt: z.string(),
})
export const requestValidateConfirmDto = z.any()

/* Favorite Addresses */
export const favoriteAddressDto = z.object({
  _id: z.string(),
  address: z.string().optional(),
  number: z.string().optional(),
  alias: z.string(),
  postalCode: z.string().optional(),
  city: z.string(),
  state: z.string().optional(),
  latitude: z.number(),
  longitude: z.number(),
  country: z.string(),
})

export const favoriteAddressesDto = z.array(favoriteAddressDto)
export type FavoriteAddress = z.infer<typeof favoriteAddressDto>

/* Fiscal data */
export const KeyFiscalData = {
  name: 'name',
  email: 'email',
  address: 'address',
  documentType: 'documentType',
  documentNumber: 'documentNumber',
  country: 'country',
} as const

const imFiscalDataDto = z.object({
  _id: z.string().optional(),
  [KeyFiscalData.name]: z.string().optional(),
  [KeyFiscalData.email]: z.string().optional(),
  [KeyFiscalData.address]: z.string().optional(),
  [KeyFiscalData.documentType]: z.string().optional(),
  [KeyFiscalData.documentNumber]: z.string().optional(),
  [KeyFiscalData.country]: z.string().optional(),
})

export const imFiscalDataListDto = z.array(imFiscalDataDto)

export type IMFiscalData = z.infer<typeof imFiscalDataDto>

export const FiscalNoteFields = {
  DOCUMENT_TYPE: 'type',
  DOCUMENT: 'document',
  PHONE: 'phone',
} as const

export const EnrollmentScreensDto = z.object({
  index: z.number(),
  title: z.string(),
  fields: z.array(z.string()),
  active: z.boolean().optional(),
  completed: z.boolean().optional(),
})

export type EnrollmentScreensData = z.infer<typeof EnrollmentScreensDto>

export const removeTokenDto = z.object({ status: z.string() })
