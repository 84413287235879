import get from 'lodash/get'
import { defineStore } from 'pinia'
import { LOYALTY_DATA_ONBOARDING_KEYS } from '~/lib/models/data-onboarding'
import { imService } from '~/lib/services/im/im.service'
import { loyaltyService } from '~/lib/services/lotalty/loyalty.service'
import { Customer, CustomerMetadata, customerMetadataDto } from '~/lib/services/store/auth.dto'

interface State {
  user?: Customer
  token?: string
  country?: string
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: undefined,
    token: undefined,
    country: undefined,
  }),
  actions: {
    async getUser() {
      if (!this.token) return
      try {
        if (this.isUserLogged) {
          const userReloaded = !!(await this.getProfile())
          if (!userReloaded) this.removeToken()
          return true
        }
        // !!! WIP, TODO = user and token are not saving on store, without [...page] Loader v-else cookie at least but not localStorage :(
        this.user = await imService.getProfile(this.token)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ file: auth.store.ts:29 ~ getUser ~ err:', err)
        this.removeToken()
      }
      return true
    },
    async getProfile() {
      if (!this.token) return
      try {
        this.user = await imService.getProfile(this.token)
        if (process.client) {
          const pendingRegistration = window.localStorage.getItem(
            `${this.userMcId}-pending-registration`
          )
          if (!this.isLoyaltyUser && pendingRegistration === 'true') {
            try {
              await this.registerUserInLoyalty()
              window.localStorage.removeItem(`${this.userMcId}-pending-registration`)
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log(
                '🚀 ~ file: auth.store.ts:37 ~ getProfile-registerUserInLoyalty ~ err:',
                err
              )
            }
          }
        }
        return this.user
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ file: auth.store.ts:37 ~ getProfile ~ err:', err)
        return undefined
      }
    },
    async updateUser(metadata: CustomerMetadata, checkCpf?: boolean) {
      if (!this.token) return
      try {
        this.user = await imService.setIMProfile({
          accessToken: this.token,
          metadata,
          checkCpf,
        })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ file: auth.store.ts:55 ~ updateUser ~ err:', err)
        throw err
      }
    },
    setToken(token: string) {
      this.token = token
    },
    setCountry(country: string) {
      this.country = country
    },
    async removeToken() {
      try {
        const appStore = useAppStore()
        if (this.token) {
          await imService.logout(this.token)
          // Clear user related data (promos, tips... etc)
          await appStore.setCartTips(undefined)
          await appStore.setPromotion({ promo: undefined })
        }
      } catch (error) {
      } finally {
        this.user = undefined
        this.token = undefined
        // remove auth cookie
        imService.removeTokenCookie().catch(() => {})
        this.country = undefined
      }
    },
    async registerInLoyalty(country: string) {
      if (!this.token) return
      try {
        await loyaltyService.registerLoyalty({
          accessToken: this.token,
          country,
        })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('🚀 ~ file: auth.store.ts:55 ~ updateUser ~ err:', err)
        throw err
      }
    },
    async registerUserInLoyalty(maxRetries = 3, interval = 1000) {
      const country = useCountry()
      for (let tries = 0; tries < maxRetries; tries++) {
        try {
          await this.registerInLoyalty(country)
          return
        } catch (error) {
          if (tries === maxRetries - 1) throw error
          await new Promise(resolve => setTimeout(resolve, interval))
        }
      }
    },
  },
  getters: {
    userMetadata: state =>
      state.user &&
      customerMetadataDto.parse(
        state.user.metadata.reduce<Record<string, string>>(
          (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
          {}
        )
      ),
    isUserLogged: state => !!(state.token && state.user),
    isLoyaltyUser(): boolean | undefined {
      if (!this.isUserLogged) return undefined
      const loyaltyMetadata = this.userMetadata?.loyalty
      if (!loyaltyMetadata) return false
      return loyaltyMetadata === 'true'
    },
    userMcId(): string | undefined {
      if (!this.isUserLogged) return undefined
      const mcIdMetadata = this.userMetadata?.mcId
      if (!mcIdMetadata) return undefined
      return mcIdMetadata
    },
    isClubVipAutomacUser(): boolean | undefined {
      if (!this.isUserLogged) return undefined
      const clubVipAutomacMetadata = this.userMetadata?.clubVipAutomac
      if (!clubVipAutomacMetadata) return undefined
      return clubVipAutomacMetadata === 'true'
    },
    isNeededPhoneValidation() {
      if (!this.isUserLogged || !this.user) return false
      const prefixMetadata = this.user.metadata.find(m => m.key === 'phoneNumberPrefix')
      const sufixMetadata = this.user.metadata.find(m => m.key === 'phoneNumberSufix')
      if (prefixMetadata?.verified && sufixMetadata?.verified) return false
      return true
    },
    unfilledPaymentFields() {
      if (!this.isUserLogged) return undefined
      const appStore = useAppStore()
      const requiredPaymentFields = appStore.ecommerceConfig?.requiredPaymentFields
      if (!requiredPaymentFields?.length) return undefined
      const requiredFields = requiredPaymentFields.filter(field => {
        if (
          ['phoneNumberPrefix', 'phoneNumberSufix'].includes(field) &&
          appStore.phoneValidationActive
        )
          return false
        return true
      })
      const unfilledFields = []
      const userMetadata = this.userMetadata
      for (const field of requiredFields) {
        const value = get(userMetadata, field, undefined)
        if (!value) {
          unfilledFields.push(field)
        }
      }
      return unfilledFields.length ? unfilledFields : undefined
    },
    userHasBirthdateStr(): boolean {
      if (!this.isUserLogged || !this.user) return false
      const birthDateStrMetadata = this.user.metadata.find(
        m => m.key === LOYALTY_DATA_ONBOARDING_KEYS.BIRTHDATE_STR
      )
      return !!birthDateStrMetadata
    },
    hasRequiredLoyaltyData(): any {
      if (!this.isUserLogged || !this.user) return false
      if (this.isLoyaltyUser) return true
      const { loyaltyRequiredFields } = useAppStore()
      const hasRequiredFields = loyaltyRequiredFields.every(field => {
        switch (field) {
          case 'name':
            return Boolean(this?.userMetadata?.firstname)
          case 'lastName':
            return Boolean(this?.userMetadata?.lastname)
          case 'phone':
            return Boolean(this?.userMetadata?.phoneNumberSufix)
          case 'birthDateStr':
            return Boolean(this?.userMetadata?.birthDateStr)
          case 'cpf':
            return Boolean(this?.userMetadata?.cpf)
          case 'documentType':
            return Boolean(this?.userMetadata?.documentType)
          default:
            return true
        }
      })
      return hasRequiredFields
    },
  },
  persist: [
    {
      paths: ['token'],
      storage: persistedState.cookiesWithOptions({
        secure: true,
        httpOnly: true,
      }),
    },
    {
      paths: ['user'],
      storage: persistedState.localStorage,
    },
    {
      paths: ['country'],
      storage: persistedState.localStorage,
    },
  ],
})
