import revive_payload_client_xVGgLv27Vw from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zvTDMjTf6s from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Qp0dPFRNE5 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4Dx2xxxef1 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_UhR5EjSzA3 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_L47aV2xVq5 from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.14.1_typescript@5.4.4_vue@3.4.21_typescript@5.4.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_gQO3eNADU7 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_csIoMgYc1n from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.0_axios@1.6.8_debug_6j7wgpvbuci4tvehfh2dxal33u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_RSO9xzvBOu from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11_rollup@4.14.1_typescript@5.4.4_vue_ie2feymdwxtjckg2vk5qk7dmte/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _0_debug_jqdI55muo8 from "/app/plugins/0.debug.ts";
import _1_primevue_lxAzGxK1Ee from "/app/plugins/1.primevue.ts";
import _2_i18n_EuExFkfPVU from "/app/plugins/2.i18n.ts";
import _3_toast_8LGPUlHz51 from "/app/plugins/3.toast.ts";
import _4_vueGoogleMaps_E9BuWZIoNu from "/app/plugins/4.vueGoogleMaps.ts";
import _5_maska_I1ORx6LyoP from "/app/plugins/5.maska.ts";
import _6_dompurify_Z2gImxgCYb from "/app/plugins/6.dompurify.ts";
import _7_vue_popper_cVwbVdb9Hi from "/app/plugins/7.vue-popper.ts";
import _8_auth_client_N0Q8j4Qbro from "/app/plugins/8.auth.client.ts";
import _8_rum_XiqsCVRQIv from "/app/plugins/8.rum.ts";
import plugin_client_QHzhVBB2Ll from "/app/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_xVGgLv27Vw,
  unhead_zvTDMjTf6s,
  router_Qp0dPFRNE5,
  payload_client_4Dx2xxxef1,
  check_outdated_build_client_UhR5EjSzA3,
  plugin_vue3_L47aV2xVq5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gQO3eNADU7,
  chunk_reload_client_csIoMgYc1n,
  plugin_RSO9xzvBOu,
  _0_debug_jqdI55muo8,
  _1_primevue_lxAzGxK1Ee,
  _2_i18n_EuExFkfPVU,
  _3_toast_8LGPUlHz51,
  _4_vueGoogleMaps_E9BuWZIoNu,
  _5_maska_I1ORx6LyoP,
  _6_dompurify_Z2gImxgCYb,
  _7_vue_popper_cVwbVdb9Hi,
  _8_auth_client_N0Q8j4Qbro,
  _8_rum_XiqsCVRQIv,
  plugin_client_QHzhVBB2Ll
]