import { default as _91_46_46_46page_93LN8p5ClGpLMeta } from "/app/pages/[...page].vue?macro=true";
import { default as _91_46_46_46question_93EjjskBH3FcMeta } from "/app/pages/faqs/[...question].vue?macro=true";
import { default as faqsUUYDsPBK4IMeta } from "/app/pages/faqs.vue?macro=true";
import { default as _91_46_46_46home_93azBOWYKpZ8Meta } from "/app/pages/home/[...home].vue?macro=true";
import { default as minecraftBXz2uHOUfeMeta } from "/app/pages/landing/minecraft.vue?macro=true";
import { default as indexik2UgETBQcMeta } from "/app/pages/menu/[categorySlug]/[productSlug]/index.vue?macro=true";
import { default as indexweTCzzXShzMeta } from "/app/pages/menu/[categorySlug]/index.vue?macro=true";
import { default as indexKEaHWmyZdKMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as indexIl1xNQSK9RMeta } from "/app/pages/my-account/[sectionSlug]/index.vue?macro=true";
import { default as my_45devicesWEbYqNy7EpMeta } from "/app/pages/my-account/[sectionSlug]/my-devices.vue?macro=true";
import { default as indexHWrmghCRAPMeta } from "/app/pages/my-account/index.vue?macro=true";
import { default as indexavtM4VLwe9Meta } from "/app/pages/my-account/my-orders/[orderId]/index.vue?macro=true";
import { default as index78wn2IGGI2Meta } from "/app/pages/my-account/my-orders/index.vue?macro=true";
import { default as indexumj10Ud4GVMeta } from "/app/pages/orders/cart/[cartProductIndex]/index.vue?macro=true";
import { default as index2dDytFO0uDMeta } from "/app/pages/orders/cart/index.vue?macro=true";
import { default as indexbjAMUdCSMQMeta } from "/app/pages/orders/checkout/index.vue?macro=true";
import { default as _91_46_46_46slug_938AOO8m1WqhMeta } from "/app/pages/orders/menu/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93hmp4w1kY5cMeta } from "/app/pages/products/[...slug].vue?macro=true";
import { default as indexSYdeBpa0eYMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/index.vue?macro=true";
import { default as indexWYrWd22eB8Meta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/[productSlug]/index.vue?macro=true";
import { default as index4F5WuZwz1oMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/index.vue?macro=true";
import { default as indexiJYzGpPuBlMeta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/index.vue?macro=true";
import { default as index6wjsFR7yW2Meta } from "/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/index.vue?macro=true";
import { default as indexo9ITt7Hj9xMeta } from "/app/pages/restaurants/[citySlug]/index.vue?macro=true";
import { default as indexEnDopdLMFLMeta } from "/app/pages/restaurants/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93LN8p5ClGpLMeta?.name ?? "page",
    path: _91_46_46_46page_93LN8p5ClGpLMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93LN8p5ClGpLMeta || {},
    alias: _91_46_46_46page_93LN8p5ClGpLMeta?.alias || [],
    redirect: _91_46_46_46page_93LN8p5ClGpLMeta?.redirect,
    component: () => import("/app/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: faqsUUYDsPBK4IMeta?.name ?? "faqs",
    path: faqsUUYDsPBK4IMeta?.path ?? "/faqs",
    meta: faqsUUYDsPBK4IMeta || {},
    alias: faqsUUYDsPBK4IMeta?.alias || [],
    redirect: faqsUUYDsPBK4IMeta?.redirect,
    component: () => import("/app/pages/faqs.vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46question_93EjjskBH3FcMeta?.name ?? "faqs-question",
    path: _91_46_46_46question_93EjjskBH3FcMeta?.path ?? ":question(.*)*",
    meta: _91_46_46_46question_93EjjskBH3FcMeta || {},
    alias: _91_46_46_46question_93EjjskBH3FcMeta?.alias || [],
    redirect: _91_46_46_46question_93EjjskBH3FcMeta?.redirect,
    component: () => import("/app/pages/faqs/[...question].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_46_46_46home_93azBOWYKpZ8Meta?.name ?? "home-home",
    path: _91_46_46_46home_93azBOWYKpZ8Meta?.path ?? "/home/:home(.*)*",
    meta: _91_46_46_46home_93azBOWYKpZ8Meta || {},
    alias: _91_46_46_46home_93azBOWYKpZ8Meta?.alias || [],
    redirect: _91_46_46_46home_93azBOWYKpZ8Meta?.redirect,
    component: () => import("/app/pages/home/[...home].vue").then(m => m.default || m)
  },
  {
    name: minecraftBXz2uHOUfeMeta?.name ?? "landing-minecraft",
    path: minecraftBXz2uHOUfeMeta?.path ?? "/landing/minecraft",
    meta: minecraftBXz2uHOUfeMeta || {},
    alias: minecraftBXz2uHOUfeMeta?.alias || [],
    redirect: minecraftBXz2uHOUfeMeta?.redirect,
    component: () => import("/app/pages/landing/minecraft.vue").then(m => m.default || m)
  },
  {
    name: indexik2UgETBQcMeta?.name ?? "menu-categorySlug-productSlug",
    path: indexik2UgETBQcMeta?.path ?? "/menu/:categorySlug()/:productSlug()",
    meta: indexik2UgETBQcMeta || {},
    alias: indexik2UgETBQcMeta?.alias || [],
    redirect: indexik2UgETBQcMeta?.redirect,
    component: () => import("/app/pages/menu/[categorySlug]/[productSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexweTCzzXShzMeta?.name ?? "menu-categorySlug",
    path: indexweTCzzXShzMeta?.path ?? "/menu/:categorySlug()",
    meta: indexweTCzzXShzMeta || {},
    alias: indexweTCzzXShzMeta?.alias || [],
    redirect: indexweTCzzXShzMeta?.redirect,
    component: () => import("/app/pages/menu/[categorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKEaHWmyZdKMeta?.name ?? "menu",
    path: indexKEaHWmyZdKMeta?.path ?? "/menu",
    meta: indexKEaHWmyZdKMeta || {},
    alias: indexKEaHWmyZdKMeta?.alias || [],
    redirect: indexKEaHWmyZdKMeta?.redirect,
    component: () => import("/app/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: indexIl1xNQSK9RMeta?.name ?? "my-account-sectionSlug",
    path: indexIl1xNQSK9RMeta?.path ?? "/my-account/:sectionSlug()",
    meta: indexIl1xNQSK9RMeta || {},
    alias: indexIl1xNQSK9RMeta?.alias || [],
    redirect: indexIl1xNQSK9RMeta?.redirect,
    component: () => import("/app/pages/my-account/[sectionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: my_45devicesWEbYqNy7EpMeta?.name ?? "my-account-sectionSlug-my-devices",
    path: my_45devicesWEbYqNy7EpMeta?.path ?? "/my-account/:sectionSlug()/my-devices",
    meta: my_45devicesWEbYqNy7EpMeta || {},
    alias: my_45devicesWEbYqNy7EpMeta?.alias || [],
    redirect: my_45devicesWEbYqNy7EpMeta?.redirect,
    component: () => import("/app/pages/my-account/[sectionSlug]/my-devices.vue").then(m => m.default || m)
  },
  {
    name: indexHWrmghCRAPMeta?.name ?? "my-account",
    path: indexHWrmghCRAPMeta?.path ?? "/my-account",
    meta: indexHWrmghCRAPMeta || {},
    alias: indexHWrmghCRAPMeta?.alias || [],
    redirect: indexHWrmghCRAPMeta?.redirect,
    component: () => import("/app/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexavtM4VLwe9Meta?.name ?? "my-account-my-orders-orderId",
    path: indexavtM4VLwe9Meta?.path ?? "/my-account/my-orders/:orderId()",
    meta: indexavtM4VLwe9Meta || {},
    alias: indexavtM4VLwe9Meta?.alias || [],
    redirect: indexavtM4VLwe9Meta?.redirect,
    component: () => import("/app/pages/my-account/my-orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: index78wn2IGGI2Meta?.name ?? "my-account-my-orders",
    path: index78wn2IGGI2Meta?.path ?? "/my-account/my-orders",
    meta: index78wn2IGGI2Meta || {},
    alias: index78wn2IGGI2Meta?.alias || [],
    redirect: index78wn2IGGI2Meta?.redirect,
    component: () => import("/app/pages/my-account/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexumj10Ud4GVMeta?.name ?? "orders-cart-cartProductIndex",
    path: indexumj10Ud4GVMeta?.path ?? "/orders/cart/:cartProductIndex()",
    meta: indexumj10Ud4GVMeta || {},
    alias: indexumj10Ud4GVMeta?.alias || [],
    redirect: indexumj10Ud4GVMeta?.redirect,
    component: () => import("/app/pages/orders/cart/[cartProductIndex]/index.vue").then(m => m.default || m)
  },
  {
    name: index2dDytFO0uDMeta?.name ?? "orders-cart",
    path: index2dDytFO0uDMeta?.path ?? "/orders/cart",
    meta: index2dDytFO0uDMeta || {},
    alias: index2dDytFO0uDMeta?.alias || [],
    redirect: index2dDytFO0uDMeta?.redirect,
    component: () => import("/app/pages/orders/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexbjAMUdCSMQMeta?.name ?? "orders-checkout",
    path: indexbjAMUdCSMQMeta?.path ?? "/orders/checkout",
    meta: indexbjAMUdCSMQMeta || {},
    alias: indexbjAMUdCSMQMeta?.alias || [],
    redirect: indexbjAMUdCSMQMeta?.redirect,
    component: () => import("/app/pages/orders/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938AOO8m1WqhMeta?.name ?? "orders-menu-slug",
    path: _91_46_46_46slug_938AOO8m1WqhMeta?.path ?? "/orders/menu/:slug(.*)*",
    meta: _91_46_46_46slug_938AOO8m1WqhMeta || {},
    alias: _91_46_46_46slug_938AOO8m1WqhMeta?.alias || [],
    redirect: _91_46_46_46slug_938AOO8m1WqhMeta?.redirect,
    component: () => import("/app/pages/orders/menu/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93hmp4w1kY5cMeta?.name ?? "products-slug",
    path: _91_46_46_46slug_93hmp4w1kY5cMeta?.path ?? "/products/:slug(.*)*",
    meta: _91_46_46_46slug_93hmp4w1kY5cMeta || {},
    alias: _91_46_46_46slug_93hmp4w1kY5cMeta?.alias || [],
    redirect: _91_46_46_46slug_93hmp4w1kY5cMeta?.redirect,
    component: () => import("/app/pages/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexSYdeBpa0eYMeta?.name ?? "restaurants-citySlug-restaurantSlug",
    path: indexSYdeBpa0eYMeta?.path ?? "/restaurants/:citySlug()/:restaurantSlug()",
    meta: indexSYdeBpa0eYMeta || {},
    alias: indexSYdeBpa0eYMeta?.alias || [],
    redirect: indexSYdeBpa0eYMeta?.redirect,
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWYrWd22eB8Meta?.name ?? "restaurants-citySlug-restaurantSlug-orders-areaSlug-categorySlug-productSlug",
    path: indexWYrWd22eB8Meta?.path ?? "/restaurants/:citySlug()/:restaurantSlug()/orders/:areaSlug()/:categorySlug()/:productSlug()",
    meta: indexWYrWd22eB8Meta || {},
    alias: indexWYrWd22eB8Meta?.alias || [],
    redirect: indexWYrWd22eB8Meta?.redirect,
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/[productSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: index4F5WuZwz1oMeta?.name ?? "restaurants-citySlug-restaurantSlug-orders-areaSlug-categorySlug",
    path: index4F5WuZwz1oMeta?.path ?? "/restaurants/:citySlug()/:restaurantSlug()/orders/:areaSlug()/:categorySlug()",
    meta: index4F5WuZwz1oMeta || {},
    alias: index4F5WuZwz1oMeta?.alias || [],
    redirect: index4F5WuZwz1oMeta?.redirect,
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/[categorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiJYzGpPuBlMeta?.name ?? "restaurants-citySlug-restaurantSlug-orders-areaSlug",
    path: indexiJYzGpPuBlMeta?.path ?? "/restaurants/:citySlug()/:restaurantSlug()/orders/:areaSlug()",
    meta: indexiJYzGpPuBlMeta || {},
    alias: indexiJYzGpPuBlMeta?.alias || [],
    redirect: indexiJYzGpPuBlMeta?.redirect,
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/[areaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: index6wjsFR7yW2Meta?.name ?? "restaurants-citySlug-restaurantSlug-orders",
    path: index6wjsFR7yW2Meta?.path ?? "/restaurants/:citySlug()/:restaurantSlug()/orders",
    meta: index6wjsFR7yW2Meta || {},
    alias: index6wjsFR7yW2Meta?.alias || [],
    redirect: index6wjsFR7yW2Meta?.redirect,
    component: () => import("/app/pages/restaurants/[citySlug]/[restaurantSlug]/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexo9ITt7Hj9xMeta?.name ?? "restaurants-citySlug",
    path: indexo9ITt7Hj9xMeta?.path ?? "/restaurants/:citySlug()",
    meta: indexo9ITt7Hj9xMeta || {},
    alias: indexo9ITt7Hj9xMeta?.alias || [],
    redirect: indexo9ITt7Hj9xMeta?.redirect,
    component: () => import("/app/pages/restaurants/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEnDopdLMFLMeta?.name ?? "restaurants",
    path: indexEnDopdLMFLMeta?.path ?? "/restaurants",
    meta: indexEnDopdLMFLMeta || {},
    alias: indexEnDopdLMFLMeta?.alias || [],
    redirect: indexEnDopdLMFLMeta?.redirect,
    component: () => import("/app/pages/restaurants/index.vue").then(m => m.default || m)
  }
]